export function shortenAddress(address: string, tailCharactersAmount?: number) {
  if (!tailCharactersAmount || tailCharactersAmount < 1) {
    return (
      address.substring(0, 6) + "..." + address.substring(address.length - 4)
    );
  }
  if (tailCharactersAmount >= 20) {
    return address;
  }
  return (
    address.substr(0, 2 + Math.floor(tailCharactersAmount)) +
    "..." +
    address.substr(address.length - Math.floor(tailCharactersAmount))
  );
}

export function shortenBalance(balance: string) {
  let [integer, decimals] = balance.split(".");
  if (decimals.length > 6)
    decimals =
      decimals.substring(0, 3) +
      "..." +
      decimals.substring(decimals.length - 3);
  return integer + "." + decimals;
}

export const abbrLargeNumber = (num: number) => {
  const n = Math.round(num);
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export function normalizeString(aString: string) {
  return aString.trim().toLowerCase();
}

export function cleanString(aString: string) {
  return aString.trim().replace(/\s+/g, " ");
}

export function isStringPercentage(text: string, scale = 1): boolean {
  const stringForRegex = `^[1-9]\\d?$|^[1-9]\\d?\\.\\d{0,${scale}}$|^100$|^100\\.0{0,${scale}}$|^0$|^0\\.\\d{0,${scale}}$|^$`;
  const percentRegex = new RegExp(stringForRegex);
  return percentRegex.test(text);
}

export function laxParseFloat(value: string): number {
  return parseFloat(value === "" ? "0" : value);
}
