import { ReactNode, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { connectorsByName } from "../../../../misc/connectors/connectors";
import { resetWalletConnector } from "../../../../misc/connectors/resetWalletConnector";
import { TITLES, VICTIM_LOGOS } from "../../../MainPage/data";

export interface ConnectionProps {
  name: string;
  icon: ReactNode;
  link?: string;
}
export interface ConnectWalletProps {
  cabinet?: TCabinet | "main";
  connections: ConnectionProps[];
  title?: string;
  open: boolean;
  modal?: boolean;
  trigger?: ReactNode;
}
export const ConnectWallet = ({
  modal = false,
  cabinet,
  connections,
  title,
  open,
}: ConnectWalletProps) => {
  const { activate, account } = useEthers();
  const { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const parentPath = url.substring(0, url.lastIndexOf("/"));
    account && history.push([parentPath, ""].join(""));
  }, [account, history, url]);

  const redirectToLink = (link: string) => {
    window.open(link, "_blank")?.focus();
  };

  return (
    <Dialog.Root open={open} modal={modal}>
      <Dialog.DialogTrigger />
      <Dialog.Overlay />
      <Dialog.Content
        className={clsx(
          "v-actions",
          `${cabinet}-actions`,
          `${cabinet}-actions-connect-wallet`
        )}
      >
        {title &&
          <Dialog.Title>
            <Text cabinet={cabinet}>{title}</Text>
          </Dialog.Title>
        }
        <Dialog.DialogDescription
          as="div"
          className={"connection-list-wrapper"}
        >
          {connections.map(({ name, icon, link }, i) =>
            link ? (
              <Button
                variant={"listElement"}
                key={`button-${i}`}
                icon={icon}
                cabinet={cabinet}
                onClick={() => redirectToLink(link)}
              >
                <Text cabinet={cabinet}>{name}</Text>
              </Button>
            ) : (
              <Button
                variant={"listElement"}
                key={`button-${i}`}
                icon={icon}
                cabinet={cabinet}
                onClick={() => {
                  if (name === "Wallet Connect") {
                    resetWalletConnector(connectorsByName[name]);
                  }
                  activate(connectorsByName[name]);
                }}
              >
                <Text cabinet={cabinet}>{name}</Text>
              </Button>
            )
          )}
        </Dialog.DialogDescription>
        {cabinet !== "main" && (
          <img
            className={"connection-wallet-logo"}
            src={VICTIM_LOGOS[cabinet]}
            alt={TITLES[cabinet]}
          />
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
