import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { FortmaticConnector } from "./Fortmatic";

const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_ARCHIVE_NODE as string,
};

export const fortmatic = new FortmaticConnector({
  apiKey: process.env.REACT_APP_FORTMATIC_API_KEY as string,
  chainId: 1,
});

export const injected = new InjectedConnector({
  supportedChainIds: [1],
});

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[1] },
  defaultChainId: 1,
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: "Enso Arcade",
});

enum ConnectorNames {
  Injected = "MetaMask",
  Network = "Network",
  WalletConnect = "Wallet Connect",
  CoinbaseConnect = "Coinbase Wallet",
  Fortmatic = "Fortmatic",
}

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Network]: network,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.CoinbaseConnect]: walletlink,
  [ConnectorNames.Fortmatic]: fortmatic,
};
