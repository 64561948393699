import { ChainId, CHAIN_NAMES } from "@usedapp/core";

const infuraProjectId = "ea9186ac1297402797f68a9fae1284aa";
export const COVALENT_KEY = "ckey_ea15dda605fa46dba4ac8b7ceb4";
export const COVALENT_URL = "https://api.covalenthq.com/v1";

const infuraRpcUrl = (network: string) =>
  `https://${network}.infura.io/v3/${infuraProjectId}`;

const applicationConfig = {
  network: {
    urls: {
      1:
        process.env.REACT_APP_READONLY_NETWORK === "localhost"
          ? "http://127.0.0.1:8545/"
          : process.env.REACT_APP_READONLY_NETWORK === "enso"
          ? window.location.origin + "/enso-network/"
          : infuraRpcUrl(CHAIN_NAMES[ChainId.Mainnet]),
    },
    defaultChainId: ChainId.Mainnet,
  },

  locales: {
    enUS: {
      locale: "en-US",
      currency: "USD",
    },
  },

  createPortfolio: {
    defaultThreshold: 50,
    defaultSlippage: 995,
  },

  leaderboards: {
    portfolioCard: {
      tokenIconsCount: 3,
    },
  },
};

export function getApplicationConfig() {
  return applicationConfig;
}
