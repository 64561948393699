import { toast } from "react-toastify";
import { ReactComponent as LinkIcon } from "../components/common/assets/link.svg";
import ensoIcon from "../assets/icons/ensoIcon.png";

import { VICTIM_LOGOS } from "../components/MainPage/data";
export const fireTx = async (contract, callName, args, cabinet) => {
  try {
    const txResult = await contract[callName](...args);

    toast(
      <>
        <h4>{callName.toUpperCase()} SUBMITTED</h4>
        <p>Transaction submitted successfully. Please wait...</p>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={"https://etherscan.io/tx/" + txResult.hash}
        >
          <span>
            View on etherscan
            <LinkIcon width={"12px"} height={"12px"} />
          </span>
        </a>
      </>,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: () => {
          switch (cabinet) {
            case "cookingCrypto":
              return (
                <img
                  src={VICTIM_LOGOS["cookingCrypto"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "hypedApes":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "fomo":
              return (
                <img
                  src={VICTIM_LOGOS["fomo"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "bullRun":
              return (
                <img
                  src={VICTIM_LOGOS["bullRun"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "whaleMariner":
              return (
                <img
                  src={VICTIM_LOGOS["whaleMariner"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "defiInvaders":
              return (
                <img
                  src={VICTIM_LOGOS["defiInvaders"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            default:
              return <img src={ensoIcon} alt="" width="50" height="50"></img>;
          }
        },
      }
    );

    const txResultMined = await txResult.wait();

    toast(
      <>
        <h4>{callName.toUpperCase()} SUCCESS</h4>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={"https://etherscan.io/tx/" + txResult.hash}
        >
          <span>
            etherscan
            <LinkIcon width={"12px"} height={"12px"} />
          </span>
        </a>
      </>,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: () => {
          switch (cabinet) {
            case "cookingCrypto":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "hypedApes":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "fomo":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "bullRun":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "whaleMariner":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "defiInvaders":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );

            default:
              return <img src={ensoIcon} alt="" width="50" height="50"></img>;
          }
        },
      }
    );
    return txResultMined;
  } catch (e) {
    let msg = e.toString();
    const messageArr = e?.data?.message;
    if (messageArr?.length) {
      [, msg] = messageArr?.split("with reason string");
    } else if (e.code === 4001) {
      msg = "Transaction was rejected";
    }
    toast.error(
      <>
        <h4>{callName.toUpperCase()} FAILED</h4>
        <span>{msg}</span>
      </>,
      {
        className: `${cabinet}-notification`,
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: () => {
          switch (cabinet) {
            case "cookingCrypto":
              return (
                <img
                  src={VICTIM_LOGOS["cookingCrypto"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "hypedApes":
              return (
                <img
                  src={VICTIM_LOGOS["hypedApes"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "fomo":
              return (
                <img
                  src={VICTIM_LOGOS["fomo"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "bullRun":
              return (
                <img
                  src={VICTIM_LOGOS["bullRun"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "whaleMariner":
              return (
                <img
                  src={VICTIM_LOGOS["whaleMariner"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );
            case "defiInvaders":
              return (
                <img
                  src={VICTIM_LOGOS["whaleMariner"]}
                  alt=""
                  width="50"
                  height="50"
                ></img>
              );

            default:
              return "❌";
          }
        },
      }
    );
    throw e;
  }
};
