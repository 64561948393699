import { ChainId, useEthers } from "@usedapp/core";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import arcadeAudio from "../../assets/audio/arcadeAudio.mp3";
import arcadeOff from "../../assets/fast/arcadeOff.webm";
import arcadeOn from "../../assets/fast/arcadeOn.webm";
import "../../assets/font/font.css";
import arcadeOnPreview from "../../assets/preview/arcadeOnPreview.png";
import slowArcadeOff from "../../assets/slow/slowArcadeOff.webm";
import slowArcadeOn from "../../assets/slow/slowArcadeOn.webm";
// import { useEthers } from "@usedapp/core";
import { NetworkSpeed, useNetworkSpeed } from "../../misc/useNetworkSpeed";
import { ConnectWallet } from "../common/Actions/actions";
import CoinbaseWalletIcon from "../common/assets/coinbase.png";
import MetaMaskIcon from "../common/assets/metamask.png";
import FortMaticIcon from "../common/assets/fortmatic.png";
import WalletConnectIcon from "../common/assets/wallet-connect.png";
import Cabinet from "../common/Cabinet/Cabinet";
import { Preload } from "../common/Preload/Preload";
import { CABINETS, PAGE_SECTIONS } from "./data";
import "./mainPage.scss";
import { Nav } from "./Nav/Nav";
import { AppState, appStateReducer, cabinetStateReducer } from "./reducer";
import { TvlInfo } from "./TvlInfo/TvlInfo";
import { MasterReward } from "./MasterReward";
import { getPriceData } from "../../services/getPriceData";
import { getUserHasClaimed } from "../../services/getUserHasClaimed";
import { VICTIM_META } from "../../config/victimMeta";
import { getStakedAmount } from "../../services/getStakedAmount";
import { getStakedCount } from "../../services/getStakedCount";
import { formatUnits } from "@ethersproject/units";
import { providers } from "@usedapp/core/node_modules/ethers";
import { Button } from "../common/Button";
import { getTotalTvl } from "../../services/getTotalTvl";
function MainPage() {
  const { account, library, chainId } = useEthers();
  const [appState, dispatchAppState] = useReducer(
    appStateReducer,
    AppState.LOADING_ARCADE
  );
  const [cabinetState, dispatchCabinetState] = useReducer(
    cabinetStateReducer,
    CABINETS.reduce((mem, cabinet) => ({ ...mem, [cabinet]: false }), {})
  );

  const netWorkSpeed = useNetworkSpeed();
  const history = useHistory();
  const [longLoad, setLongLoad] = useState(false);
  const [cabinetOn, setCabinetOn] = useState<TCabinet | null>(null);
  const [connectDialogOpen, setConnectDialogOpen] = useState(false);
  const [audioReady, setAudioReady] = useState(false);
  const [arcadeOffReady, setArcadeOffReady] = useState(false);
  const [arcadeOnReady, setArcadeOnReady] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const iframeAudioRef = useRef<HTMLIFrameElement>(null);
  const arcadeOnRef = useRef<HTMLVideoElement>(null);
  const arcadeOffRef = useRef<HTMLVideoElement>(null);

  const [tokenPrices, setTokenPrices] = useState({});

  useEffect(() => {
    const ALL_TOKENS = Object.values(VICTIM_META)
      .map((victim) => Object.values(victim.indexes))
      .flat(1);
    const getPrices = async () => {
      const tokenPrices = await getPriceData(ALL_TOKENS);
      setTokenPrices(tokenPrices);
    };
    getPrices();
  }, []);

  const [networkSupported, setNetworkSupported] = useState(true);
  const [readOnly, setReadOnly] = useState(true);

  const connectToMainnet = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${ChainId.Mainnet.toString(16)}` }], // chainId must be in hexadecimal numbers
        });
      } else {
        setReadOnly(true);
      }
    } catch (e) {
      if (e.code === 4001) setReadOnly(true);
    }
  };

  useEffect(() => {
    if (account && chainId) {
      if (chainId === ChainId.Mainnet) {
        setNetworkSupported(true);
        setReadOnly(false);
      } else {
        setNetworkSupported(false);
        setReadOnly(true);
      }
    }
  }, [chainId, account]);

  const [fetchMasterStatus, setFetchMasterStatus] = useState(true);
  const [masterClaimed, setMasterClaimed] = useState(false);
  const [canClaimMaster, setCanClaimMaster] = useState(false);
  const [showMaster, setShowMaster] = useState(false);

  useEffect(() => {
    const masterSeen = localStorage.getItem("masterSeen");
    if (!masterSeen) {
      setShowMaster(true);
      localStorage.setItem("masterSeen", "true");
    }
  }, []);

  const [claimedCabinets, setClaimedCabinets] = useState([]);

  useEffect(() => {
    if (library && account && !readOnly) {
      const getMasterRewardStatus = async () => {
        const VICTIM_LENGTH = Object.values(VICTIM_META).length;
        const masterClaimed = await getUserHasClaimed(
          VICTIM_LENGTH,
          library,
          account
        );
        setMasterClaimed(masterClaimed);

        let canClaimMaster = true;
        for (let cabinet of CABINETS) {
          const adapter = VICTIM_META[cabinet].adapter;
          const hasClaimed = await getUserHasClaimed(adapter, library, account);
          if (!hasClaimed) canClaimMaster = false;
          setClaimedCabinets((claimedCabinets) => {
            const newClaimedCabinets = [...claimedCabinets];
            const oldClaimedCabinetIndex = newClaimedCabinets.findIndex(
              (oldClaimedCabinet) => oldClaimedCabinet.cabinet === cabinet
            );
            if (oldClaimedCabinetIndex >= 0) {
              newClaimedCabinets[oldClaimedCabinetIndex].hasClaimed =
                hasClaimed;
            } else newClaimedCabinets.push({ cabinet, hasClaimed });
            return newClaimedCabinets;
          });
        }
        setCanClaimMaster(canClaimMaster);
        //setShowMaster(!masterClaimed && canClaimMaster);
      };

      getMasterRewardStatus();
    }
  }, [account, library, readOnly, fetchMasterStatus]);

  const [userTvlInfo, setUserTvlInfo] = useState([]);

  useEffect(() => {
    const getUserTvl = async () => {
      const allStakedAmount = await getStakedAmount(account);
      CABINETS.forEach((cabinet) => {
        const cabinetTokens = Object.entries(VICTIM_META[cabinet].indexes);
        cabinetTokens.forEach(async ([tokenSymbol, tokenAddress]) => {
          const stakedAmount = allStakedAmount.find(
            (staked) => staked.token.id === tokenAddress
          );
          const stakedAmountNumber = stakedAmount
            ? Number(
                formatUnits(stakedAmount.amount, stakedAmount.token.decimals)
              )
            : 0;
          setUserTvlInfo((userTvlInfo) => {
            const newUserTvlInfo = [...userTvlInfo];
            const oldUserTvlIndex = newUserTvlInfo.findIndex(
              (oldUserTvl) => oldUserTvl.cabinet === cabinet
            );
            if (oldUserTvlIndex >= 0) {
              newUserTvlInfo[oldUserTvlIndex].totalStaked[tokenSymbol] =
                stakedAmountNumber;
            } else
              newUserTvlInfo.push({
                cabinet,
                totalStaked: { [tokenSymbol]: stakedAmountNumber },
              });
            return newUserTvlInfo;
          });
        });
      });
    };

    if (library && account && !readOnly) {
      getUserTvl();
    }
  }, [account, readOnly, library]);

  const [totalUserTvl, setTotalUserTvl] = useState(undefined);

  useEffect(() => {
    const getUserTotalDollarTvl = async () => {
      let totalDollars = 0;
      CABINETS.forEach((cabinet) => {
        const cabinetTokens = Object.entries(VICTIM_META[cabinet].indexes);
        cabinetTokens.forEach(async ([tokenSymbol, tokenAddress]) => {
          const cabinetTvl = userTvlInfo.find((tvl) => tvl.cabinet === cabinet);

          if (cabinetTvl) {
            const stakedAmount = cabinetTvl.totalStaked[tokenSymbol];

            if (
              stakedAmount > 0 &&
              tokenPrices[tokenAddress.toLowerCase()] > 0
            ) {
              totalDollars +=
                tokenPrices[tokenAddress.toLowerCase()] * stakedAmount;
            }
          }
        });
      });
      setTotalUserTvl(totalDollars);
    };

    if (
      library &&
      account &&
      !readOnly &&
      Object.keys(tokenPrices).length > 0 &&
      Object.keys(userTvlInfo).length > 0
    ) {
      getUserTotalDollarTvl();
    }
  }, [account, library, tokenPrices, userTvlInfo, readOnly]);

  const [tvlInfo, setTvlInfo] = useState([]);

  useEffect(() => {
    const getTvl = async () => {
      const provider = readOnly
        ? process.env.REACT_APP_READONLY_NETWORK === "localhost"
          ? new providers.JsonRpcProvider("http://127.0.0.1:8545/")
          : process.env.REACT_APP_READONLY_NETWORK === "enso"
          ? new providers.JsonRpcProvider(
              window.location.origin + "/enso-network/"
            )
          : library
        : library;

      const allStakedCount = await getStakedCount();
      const totalTvl = await getTotalTvl();
      CABINETS.forEach(async (cabinet) => {
        const stakedCount = allStakedCount.find(
          (stakedCount) =>
            stakedCount.name.toLowerCase() ===
            VICTIM_META[cabinet].victim.toLowerCase() + "adapter"
        );
        let tvl = undefined;
        if (Object.keys(tokenPrices).length > 0) {
          const cabinetTokens = Object.entries(VICTIM_META[cabinet].indexes);
          cabinetTokens.forEach(async ([tokenSymbol, tokenAddress]) => {
            const stakedAmount = totalTvl.find(
              (tvl) => tvl.id === tokenAddress.toLowerCase()
            );
            if (!stakedAmount) return;
            const stakedAmountNumber = Number(
              formatUnits(stakedAmount.stakedAmount, stakedAmount.decimals)
            );

            if (tokenPrices[tokenAddress.toLowerCase()] !== undefined)
              tvl =
                (tvl !== undefined ? tvl : 0) +
                tokenPrices[tokenAddress.toLowerCase()] * stakedAmountNumber;
            else if (Object.keys(tokenPrices).length > 0)
              throw Error(
                "Token price not available for " +
                  tokenSymbol +
                  ": " +
                  tokenAddress
              );

            setTvlInfo((tvlInfo) => {
              const newTvlInfo = [...tvlInfo];
              const oldTvlInfoIndex = newTvlInfo.findIndex(
                (oldTvlInfo) => oldTvlInfo.cabinet === cabinet
              );
              if (oldTvlInfoIndex >= 0) {
                newTvlInfo[oldTvlInfoIndex].tvl = tvl;
                newTvlInfo[oldTvlInfoIndex].stakedCount = stakedCount
                  ? stakedCount.staked
                  : undefined;
              } else {
                newTvlInfo.push({
                  cabinet,
                  tvl,
                  stakedCount: stakedCount ? stakedCount.staked : undefined,
                });
              }
              return newTvlInfo;
            });
          });
        } else {
          setTvlInfo((tvlInfo) => {
            const newTvlInfo = [...tvlInfo];
            const oldTvlInfoIndex = newTvlInfo.findIndex(
              (oldTvlInfo) => oldTvlInfo.cabinet === cabinet
            );
            if (oldTvlInfoIndex >= 0) {
              newTvlInfo[oldTvlInfoIndex].stakedCount = stakedCount
                ? stakedCount.staked
                : undefined;
            } else {
              newTvlInfo.push({
                cabinet,
                stakedCount: stakedCount ? stakedCount.staked : undefined,
              });
            }
            return newTvlInfo;
          });
        }
      });
    };

    if (library) {
      getTvl();
    }
  }, [account, library, tokenPrices, readOnly]);

  useEffect(() => {
    switch (appState) {
      case 0:
        audioRef.current.muted = true;
        setTimeout(() => setLongLoad(true), 750);
        break;
      case 1:
        // console.log("AppState.LOADED_ARCADE_ON");
        break;
      case 2:
        // console.log("AppState.LOADING_ARCADE_OFF");
        dispatchAppState({ type: AppState.LOADING_CABINETS });
        break;
      case 3:
        // console.log("AppState.LOADING_CABINETS");
        break;
      case 4:
        // console.log("AppState.LOADED_CABINETS");
        dispatchAppState({ type: AppState.DONE });
        break;
      case 5:
        // console.log("AppState.DONE");
        break;
    }
  }, [appState]);

  const cachedMuteState = localStorage.getItem("muteState");
  const [muteState, setMuteState] = useState(cachedMuteState ?? "unmuted");
  const [mobileInteractionNeeded, setMobileInteractionNeeded] = useState(false);

  useEffect(() => {
    const currentAudio = audioRef.current;

    const playAudio = async () => {
      try {
        await currentAudio.play();
      } catch (e) {
        setMuteState("muted");
        setMobileInteractionNeeded(true);
      }
    };

    if (audioReady && (arcadeOnReady || arcadeOffReady)) {
      dispatchAppState({ type: AppState.LOADING_ARCADE_OFF });
      playAudio();
    }

    return () => {
      if (currentAudio) currentAudio.pause();
    };
  }, [audioReady, arcadeOnReady, arcadeOffReady]);

  const manageMuteState = () => {
    setMuteState(muteState === "muted" ? "unmuted" : "muted");
  };

  useEffect(() => {
    if (appState >= AppState.LOADING_ARCADE_OFF) {
      if (muteState === "unmuted") audioRef.current.play();
      if (!mobileInteractionNeeded)
        localStorage.setItem(
          "muteState",
          muteState === "muted" ? "muted" : "unmuted"
        );
    }
  }, [appState, muteState, mobileInteractionNeeded]);

  const [videoScale, setVideoScale] = useState({
    xOffset: 0,
    yOffset: 0,
    scale: 0,
  });

  useEffect(() => {
    const video = { width: 1920, height: 1080 };

    function handleResize() {
      const windowWidth = Math.min(
        window.visualViewport.width,
        window.screen.width,
        window.innerWidth
      );
      const windowHeight = Math.min(
        window.visualViewport.height,
        window.screen.height,
        window.innerHeight
      );

      const mobile =
        windowWidth < windowHeight &&
        windowWidth <= 1100 &&
        windowHeight <= 1100;
      const ultraWide = windowWidth >= 2000 && windowHeight >= 1125;

      if (mobile) document.body.classList.add("mobile");
      else document.body.classList.remove("mobile");

      if (ultraWide)
        document.querySelector(".v-main-nav").classList.remove("fixed");
      else document.querySelector(".v-main-nav").classList.add("fixed");

      document.body.style.width = `${mobile ? windowHeight : windowWidth}px`;
      document.body.style.height = `${mobile ? windowWidth : windowHeight}px`;

      const xScale = (mobile ? windowHeight : windowWidth) / video.width;
      const yScale = (mobile ? windowWidth : windowHeight) / video.height;

      let yOffset = 0;
      let xOffset = 0;
      let scale;

      if (ultraWide ? yScale > xScale : xScale > yScale) {
        scale = xScale;
        yOffset =
          ((mobile ? windowWidth : windowHeight) - video.height * scale) / 2;
      } else {
        scale = yScale;
        xOffset =
          ((mobile ? windowHeight : windowWidth) - video.width * scale) / 2;
      }

      setVideoScale({
        xOffset,
        yOffset,
        scale,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const allCabinetsLoaded = Object.values(cabinetState).every((v) => !!v);
    if (allCabinetsLoaded && appState === AppState.LOADING_CABINETS) {
      dispatchAppState({ type: AppState.LOADED_CABINETS });
    }
  }, [cabinetState, appState]);

  // const showArcadeOn =
  //   (appState >= 1 && !account) || (appState >= 1 && masterClaimed);
  // const showArcadeOff = appState >= 1 && !!account && !masterClaimed;
  const showArcadeOn = true;
  const showArcadeOff = false;

  const [volumeInterval, setVolumeInterval] = useState(null);

  useEffect(() => {
    audioRef.current.muted = muteState === "muted";
    audioRef.current.volume = 0;
    if (!cabinetOn && audioReady && !audioRef.current.muted) {
      const interval = setInterval(() => {
        try {
          if (audioRef.current.volume > 0.1) clearInterval(interval);
          else {
            audioRef.current.volume += 0.01;
            audioRef.current.volume += 0.01;
          }
        } catch (e) {
          clearInterval(interval);
        }
      }, 100);
      setVolumeInterval(interval);
    } else {
      clearInterval(volumeInterval);
    }
  }, [cabinetOn, audioReady, muteState]);

  useEffect(() => {
    if (arcadeOnReady) {
      arcadeOnRef.current.play();
    }
  }, [arcadeOnReady]);

  useEffect(() => {
    if (arcadeOffReady) {
      arcadeOffRef.current.play();
    }
  }, [arcadeOffReady]);

  const setCabinetState = useCallback((cabinet) => {
    dispatchCabinetState({ type: cabinet });
  }, []);

  return (
    <>
      {appState < AppState.DONE && (
        <div className="loading-screen">
          <div className="loading-screen-logo" />
          {longLoad && (
            <div className="loading-screen-bar-container">
              <div
                className="loading-screen-bar"
                style={{
                  width: `${(appState * 100) / AppState.DONE}%`,
                }}
              ></div>
            </div>
          )}
        </div>
      )}
      <ConnectWallet
        modal={false}
        open={connectDialogOpen && !account}
        cabinet={"main"}
        connections={[
          window.web3 && window.ethereum
            ? {
                name: "MetaMask",
                icon: (
                  <img
                    src={MetaMaskIcon}
                    className="walletIcon"
                    alt="MetaMask Icon"
                  />
                ),
              }
            : {
                name: "Install MetaMask",
                icon: (
                  <img
                    src={MetaMaskIcon}
                    className="walletIcon"
                    alt="MetaMask Icon"
                  />
                ),
                link: "https://metamask.io/",
              },
          {
            name: "Fortmatic",
            icon: (
              <img
                src={FortMaticIcon}
                className="walletIcon"
                alt="Fortmatic Icon"
              />
            ),
          },
          {
            name: "Coinbase Wallet",
            icon: (
              <img
                src={CoinbaseWalletIcon}
                className="walletIcon"
                alt="Coinbase Wallet Icon"
              />
            ),
          },
          {
            name: "Wallet Connect",
            icon: (
              <img
                src={WalletConnectIcon}
                className="walletIcon"
                alt="Wallet Connect Icon"
              />
            ),
          },
        ]}
      />
      <div className={"v-main-page"}>
        {!networkSupported && (
          <div className="switch-network">
            <div className="switch-network-container">
              <h1>Network not supported</h1>
              <Button
                variant={"rectangleLarge"}
                onClick={() => connectToMainnet()}
              >
                Connect to Mainnet
              </Button>
            </div>
          </div>
        )}
        {account ? (
          <img
            className="backgroundCover"
            src={"/assets/arcadeOff.png"}
            alt=""
          />
        ) : (
          <img className="backgroundCover" src={arcadeOnPreview} alt="" />
        )}
        <Nav
          style={appState >= 1 ? {} : { display: "none" }}
          scale={videoScale.scale}
          yOffset={videoScale.yOffset}
          xOffset={videoScale.xOffset}
          userTvlInfo={userTvlInfo}
          claimedCabinets={claimedCabinets}
          masterClaimed={masterClaimed}
          onMasterShow={() => setShowMaster(true)}
          totalUserTvl={totalUserTvl}
          account={account}
          setConnectDialogOpen={setConnectDialogOpen}
          connectDialogOpen={connectDialogOpen}
          onMuteClick={() => manageMuteState()}
          muted={muteState === "muted"}
        />
        {showMaster && (
          <MasterReward
            masterClaimed={masterClaimed}
            onOutroEnd={() => {
              setShowMaster(false);
            }}
          />
        )}
        <div className="frame-container">
          <>
            <audio
              preload="auto"
              src={arcadeAudio}
              loop
              muted={muteState === "muted"}
              ref={audioRef}
              onCanPlayThrough={() => {
                setAudioReady(true);
              }}
            />
            <img
              className={!account ? "video" : "videoHidden"}
              src={arcadeOnPreview}
              alt=""
            />
            {netWorkSpeed !== NetworkSpeed.SLOW ? (
              <video
                preload="auto"
                ref={arcadeOnRef}
                autoPlay
                muted
                loop
                className={showArcadeOn ? "video" : "videoHidden"}
                onCanPlayThrough={() => {
                  setArcadeOnReady(true);
                }}
              >
                <source src={arcadeOn} type="video/webm" />
              </video>
            ) : (
              <video
                preload="auto"
                ref={arcadeOnRef}
                autoPlay
                muted
                loop
                className={showArcadeOn ? "video" : "videoHidden"}
                onCanPlayThrough={() => {
                  setArcadeOnReady(true);
                }}
              >
                <source src={slowArcadeOn} type="video/webm" />
              </video>
            )}
            {account && (
              <>
                <img
                  className={arcadeOffReady ? "videoHidden" : "video"}
                  src={"/assets/arcadeOff.png"}
                  alt=""
                />
                {appState >= 1 && (
                  <>
                    {netWorkSpeed !== NetworkSpeed.SLOW ? (
                      <video
                        preload="auto"
                        ref={arcadeOffRef}
                        autoPlay
                        muted
                        loop
                        className={showArcadeOff ? "video" : "videoHidden"}
                        onCanPlayThrough={() => {
                          setArcadeOffReady(true);
                        }}
                      >
                        <source src={arcadeOff} type="video/webm" />
                      </video>
                    ) : (
                      <video
                        preload="auto"
                        ref={arcadeOffRef}
                        autoPlay
                        muted
                        loop
                        className={showArcadeOff ? "video" : "videoHidden"}
                        onCanPlayThrough={() => {
                          setArcadeOffReady(true);
                        }}
                      >
                        <source src={slowArcadeOff} type="video/webm" />
                      </video>
                    )}
                  </>
                )}
              </>
            )}
            {appState === AppState.DONE && (
              <div className={"invisible"}>
                {CABINETS.map((cabinet) => (
                  <Preload cabinet={cabinet} key={`preload-${cabinet}`} />
                ))}
              </div>
            )}
            {appState >= 2 && (
              <>
                {CABINETS.map((cabinet) => (
                  <button
                    style={{
                      top:
                        PAGE_SECTIONS[cabinet].top * videoScale.scale +
                        videoScale.yOffset,
                      left:
                        PAGE_SECTIONS[cabinet].left * videoScale.scale +
                        videoScale.xOffset,
                      width: PAGE_SECTIONS[cabinet].width * videoScale.scale,
                      height: PAGE_SECTIONS[cabinet].height * videoScale.scale,
                    }}
                    className={"cabinet-box"}
                    onMouseEnter={() => setCabinetOn(cabinet)}
                    onFocus={() => setCabinetOn(cabinet)}
                    onMouseLeave={() => setCabinetOn(null)}
                    onBlur={() => setCabinetOn(null)}
                    onClick={() =>
                      history.push(
                        `/${cabinet.replace(
                          /[A-Z]+(?![a-z])|[A-Z]/g,
                          ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
                        )}`
                      )
                    }
                    key={`${cabinet}-button`}
                  />
                ))}
                {CABINETS.map((cabinet) => (
                  <Cabinet
                    onLoad={setCabinetState}
                    cabinet={cabinet}
                    show={
                      (cabinet === cabinetOn && cabinetState[cabinet]) ||
                      (!masterClaimed &&
                        claimedCabinets.find(
                          (claimedCabinet) =>
                            claimedCabinet.cabinet === cabinet &&
                            claimedCabinet.hasClaimed
                        ))
                    }
                    muted={
                      muteState === "muted" ||
                      (cabinet !== cabinetOn &&
                        claimedCabinets.find(
                          (claimedCabinet) =>
                            claimedCabinet.cabinet === cabinet &&
                            claimedCabinet.hasClaimed
                        ))
                    }
                    key={`${cabinet}-cabinet`}
                  />
                ))}
                {cabinetOn && (
                  <TvlInfo
                    scale={videoScale.scale}
                    yOffset={videoScale.yOffset}
                    xOffset={videoScale.xOffset}
                    cabinet={cabinetOn}
                    tvlInfo={tvlInfo.find(
                      (tvlInfo) => tvlInfo.cabinet === cabinetOn
                    )}
                  />
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
}

export default MainPage;
