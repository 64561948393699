import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Notifications } from "./components/common/Notifications";
import BullRun from "./components/GamePages/BullRun/BullRun";
import CookingCrypto from "./components/GamePages/CookingCrypto/CookingCrypto";
import DefiInvaders from "./components/GamePages/DefiInvaders/DefiInvaders";
import Fomo from "./components/GamePages/Fomo/Fomo";
import HypedApes from "./components/GamePages/HypedApes/HypedApes";
import WhaleMariner from "./components/GamePages/WhaleMariner/WhaleMariner";
import MainPage from "./components/MainPage/MainPage";
import { Providers } from "./providers";

function Arcade() {
  return (
    <Providers>
      <Router>
        <Switch>
          <Route
            path="/fomo"
            render={(p) => (
              <Page>
                <Fomo />
              </Page>
            )}
          />
          <Route
            path="/bull-run"
            render={(p) => (
              <Page>
                <BullRun />
              </Page>
            )}
          />
          <Route
            path="/whale-mariner"
            render={(p) => (
              <Page>
                <WhaleMariner />
              </Page>
            )}
          />
          <Route
            path="/hyped-apes"
            render={(p) => (
              <Page>
                <HypedApes />
              </Page>
            )}
          />
          <Route
            path="/defi-invaders"
            render={(p) => (
              <Page>
                <DefiInvaders />
              </Page>
            )}
          />
          <Route
            path="/cooking-crypto"
            render={(p) => (
              <Page>
                <CookingCrypto />
              </Page>
            )}
          />
          <Route
            path="/"
            render={(p) => (
              <Page>
                <MainPage />
              </Page>
            )}
          />
        </Switch>
        <Notifications>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Notifications>
      </Router>
    </Providers>
  );
}

const Page = (props) => <div className={"page"} {...props} />;

export default Arcade;
