import PowerPoolIcon from "./assets/victims/powerpool.png";
import DHedgeicon from "./assets/victims/dhedge.png";
import IndexIcon from "./assets/victims/index.png";
import IndexedIcon from "./assets/victims/indexed.png";
import PieDaoIcon from "./assets/victims/piedao.png";
import TokenSetsIcon from "./assets/victims/tokenSets.png";

import PowerPoolLogo from "../GamePages/WhaleMariner/assets/Whale_Mariner_Logo.png";
import DHedgeLogo from "../GamePages/DefiInvaders/assets/DefiInvadersLogo.png";
import IndexLogo from "../GamePages/Fomo/assets/FOMO_Logo.png";
import IndexedLogo from "../GamePages/BullRun/assets/BullRunLogo.png";
import PieDaoLogo from "../GamePages/CookingCrypto/assets/CookingCryptoLogo.png";
import TokenSetsLogo from "../GamePages/HypedApes/assets/HypedApesLogo.png";

export const PAGE_SECTIONS = {
  fomo: {
    top: 470,
    left: 294,
    width: 265,
    height: 418,
  },
  bullRun: {
    top: 570,
    left: 559,
    width: 213,
    height: 285,
  },
  whaleMariner: {
    top: 540,
    left: 772,
    width: 169,
    height: 290,
  },
  hypedApes: {
    top: 515,
    left: 950,
    width: 222,
    height: 320,
  },
  defiInvaders: {
    top: 506,
    left: 1172,
    width: 211,
    height: 360,
  },
  cookingCrypto: {
    top: 490,
    left: 1383,
    width: 253,
    height: 400,
  }
};

export const CABINETS: TCabinet[] = [
  "fomo",
  "bullRun",
  "whaleMariner",
  "hypedApes",
  "defiInvaders",
  "cookingCrypto",
];

export const TITLES = {
  fomo: "FOMO",
  bullRun: "Bull Run",
  whaleMariner: "Whale Mariner",
  hypedApes: "Hyped Apes",
  defiInvaders: "Defi Invaders",
  cookingCrypto: "Cooking Crypto",
};

export const VICTIM_ICONS = {
  fomo: IndexIcon,
  bullRun: IndexedIcon,
  whaleMariner: PowerPoolIcon,
  hypedApes: TokenSetsIcon,
  defiInvaders: DHedgeicon,
  cookingCrypto: PieDaoIcon,
};

export const VICTIM_LOGOS = {
  fomo: IndexLogo,
  bullRun: IndexedLogo,
  whaleMariner: PowerPoolLogo,
  hypedApes: TokenSetsLogo,
  defiInvaders: DHedgeLogo,
  cookingCrypto: PieDaoLogo,
}