import { Contract } from "@ethersproject/contracts";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { useEthers } from "@usedapp/core";
import clsx from "clsx";
import { Button } from "../../Button";
import { StakeListElement } from "../../StakeListElement";
import { erc20Abi } from "../../../../config/abis/erc20";
import { liquidityMigrationAbi } from "../../../../config/abis/liquidityMigration";
import { useCallback, useEffect, useState } from "react";
import { ethers } from "ethers";
import { liquidityMigration, VICTIM_META } from "../../../../config/victimMeta";
import { Text } from "../../Text";
import { fireTx } from "../../../../services/fireTx";
import { useHistory, useRouteMatch } from "react-router-dom";
import "../../../common/styles/dots.scss";
import { formatUnits } from "ethers/lib/utils";

export interface StakeIndexesProps {
  cabinet: TCabinet;
  indexes: Index[];
  open: boolean;
}

export enum ButtonState {
  INITIAL,
  APPROVING,
  STAKING,
}

export const ButtonContent = ({ buttonState, data }) => {
  switch (buttonState) {
    case ButtonState.INITIAL:
      return <>Stake</>;
    case ButtonState.APPROVING:
      return (
        <>
          <>Awaiting Approval{data ? ` ${data[0]}/${data[1]}` : ""}</>
          <span className="loader">
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
          </span>
        </>
      );
    case ButtonState.STAKING:
      return (
        <>
          <>Awaiting Stake</>
          <span className="loader">
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
          </span>
        </>
      );
  }
};

export const StakeIndexes = ({ cabinet, indexes, open }: StakeIndexesProps) => {
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.INITIAL
  );
  const { library } = useEthers();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [amounts, setAmounts] = useState<string[]>(indexes.map(() => ""));
  const [progress, setProgress] = useState<number[]>(undefined);

  const onStakeHandler = useCallback(async () => {
    const signer = library?.getSigner();
    if (signer) {
      const migrationContract = new Contract(
        liquidityMigration,
        liquidityMigrationAbi,
        library.getSigner()
      );
      try {
        const stakeCalls = [[], []];
        if (indexes.length === 1) {
          const index = indexes[0];
          const amount = ethers.utils.parseUnits(amounts[0], index.decimals);
          const tokenContract = new Contract(index.address, erc20Abi, signer);
          if (index.allowance.lt(amount)) {
            setButtonState(ButtonState.APPROVING);
            await fireTx(
              tokenContract,
              "approve",
              [liquidityMigration, amount],
              cabinet
            );
          }
          setButtonState(ButtonState.STAKING);
          await fireTx(
            migrationContract,
            "stake",
            [index.address, amount, VICTIM_META[cabinet].adapter],
            cabinet
          );
          setButtonState(ButtonState.INITIAL);
          history.push(
            [url.substring(0, url.lastIndexOf("/")), "/reward"].join(""),
            { staked: indexes[0].address }
          );
        } else {
          for (const index of indexes) {
            const i = indexes.indexOf(index);
            const amount = ethers.utils.parseUnits(amounts[i], index.decimals);
            const tokenContract = new Contract(index.address, erc20Abi, signer);
            if (index.allowance.lt(amount)) {
              setButtonState(ButtonState.APPROVING);
              setProgress([i + 1, indexes.length]);
              await fireTx(
                tokenContract,
                "approve",
                [liquidityMigration, amount],
                cabinet
              );
            }
            stakeCalls[0].push(index.address);
            stakeCalls[1].push(amount);
          }
          setButtonState(ButtonState.STAKING);
          await fireTx(migrationContract, "batchStake", [...stakeCalls, VICTIM_META[cabinet].adapter], cabinet);
          setButtonState(ButtonState.INITIAL);
          history.push(
            [url.substring(0, url.lastIndexOf("/")), "/reward"].join(""),
            { staked: indexes[0].address }
          );
        }
      } catch (e) {
        setButtonState(ButtonState.INITIAL);
      }
    }
  }, [library, indexes, amounts, cabinet, history]);

  const canStake = amounts.every((v) => !!v);

  const onChangeHandler = useCallback((v, newAmounts, i) => {
    newAmounts[i] = v;
    setAmounts(newAmounts);
  }, []);

  return (
    <Dialog.Root open={open} modal={false}>
      <Dialog.Trigger />
      <Dialog.Overlay />
      <Dialog.Content className={clsx("v-actions", `${cabinet}-actions`)}>
        <Dialog.Title>
          <Text cabinet={cabinet}>Enter Amount</Text>
        </Dialog.Title>
        {indexes && (
          <Dialog.DialogDescription
            as="div"
            className={clsx("v-stake-indexes", `${cabinet}-stake-indexes`)}
          >
            <ScrollAreaPrimitive.Root className={clsx("index-list-scroll")}>
              <ScrollAreaPrimitive.Viewport
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <ul className={clsx("index-list-wrapper")}>
                  {indexes.map(
                    (
                      { symbol, originalSymbol, balance, icon, decimals },
                      i
                    ) => {
                      const max = formatUnits(balance, decimals);
                      const newAmounts = [...amounts];

                      return (
                        <StakeListElement
                          decimals={decimals}
                          max={max}
                          key={`button-${i}`}
                          icon={icon}
                          cabinet={cabinet}
                          title={originalSymbol}
                          onChangeHandler={(v) => {
                            onChangeHandler(v, newAmounts, i);
                          }}
                          onMaxHandler={() => {
                            newAmounts[i] = max;
                            setAmounts(newAmounts);
                          }}
                          value={amounts[i]}
                        />
                      );
                    }
                  )}
                </ul>
              </ScrollAreaPrimitive.Viewport>
            </ScrollAreaPrimitive.Root>
            <Button
              variant={"modalButton"}
              cabinet={cabinet}
              onClick={() => {
                if (canStake && buttonState === ButtonState.INITIAL)
                  onStakeHandler();
              }}
              disabled={!canStake || buttonState !== ButtonState.INITIAL}
            >
              <Text cabinet={cabinet}>
                <ButtonContent buttonState={buttonState} data={progress} />
              </Text>
            </Button>
            <p className="lock-period">
              Migration event will occur on the 19th of April 2022*
              <br />
              <br />* subject to minor change
            </p>
          </Dialog.DialogDescription>
        )}
        <Dialog.Close />
      </Dialog.Content>
    </Dialog.Root>
  );
};
