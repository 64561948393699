import { Button } from "../../../common/Button";
import { shortenAddress } from "../../../../misc/utils";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { CABINETS, TITLES, VICTIM_LOGOS } from "../../data";

interface TVLDropdownProps {
  account?: string;
  toggleConnect: MouseEventHandler<HTMLButtonElement>;
  userTvlInfo: any[];
  claimedCabinets: any[];
  totalUserTvl?: number;
  style?: {};
  hasStaked: boolean;
}

export const TVLDropdown = ({
  account,
  toggleConnect,
  userTvlInfo,
  claimedCabinets,
  totalUserTvl,
  style,
  hasStaked,
}: TVLDropdownProps) => {
  const history = useHistory();
  const [tvlDropdown, setTvlDropdown] = useState(false);

  const handleClose = (e) => {
    const tvlDropdownContainer = document.getElementById("main-tvlDropdown");
    if (tvlDropdownContainer && !tvlDropdownContainer.contains(e.target))
      setTvlDropdown(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <div style={{ height: "100%" }}>
      {account ? (
        <div id="main-tvlDropdown" className={clsx(`main-tvlDropdown-wrapper`)}>
          <Button
            variant={"rectangleLarge"}
            onClick={() => setTvlDropdown(hasStaked ? !tvlDropdown : false)}
          >
            {shortenAddress(account)}
          </Button>
          {tvlDropdown && (
            <div className={`main-tvlDropdown`} style={style}>
              <div className={`main-tvlDropdown-header`}>
                <div>My migrated stake</div>
                <a href="https://ensofinance.medium.com/vampire-attack-completed-7d6a2becbd3e">
                  Claim on Enso
                </a>
              </div>
              {userTvlInfo.length === 0 || claimedCabinets.length === 0 ? (
                <div className="spinner" />
              ) : (
                <div className={clsx(`main-tvlDropdown-ul`)}>
                  {CABINETS.map((cabinet) => {
                    const { totalStaked } = userTvlInfo.find(
                      (tvlInfo) => tvlInfo.cabinet === cabinet
                    );
                    const claimedCabinet = claimedCabinets.find(
                      (claimedCabinet) => claimedCabinet.cabinet === cabinet
                    );
                    return (
                      <div
                        key={`${cabinet}-main-tvlDropdown-key`}
                        className={clsx(`main-tvlDropdown-li`)}
                      >
                        <span
                          className={"left"}
                          onClick={() =>
                            history.push(
                              `/${cabinet.replace(
                                /[A-Z]+(?![a-z])|[A-Z]/g,
                                ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
                              )}`
                            )
                          }
                        >
                          <div className={"title"}>{TITLES[cabinet]}</div>
                          <div className={"amount"}>
                            {Object.entries(totalStaked).some(
                              ([_, value]) => value > 0
                            ) ? (
                              ""
                            ) : (
                              <span style={{ fontSize: "90%", color: "grey" }}>
                                No tokens staked
                              </span>
                            )}
                            {Object.entries(totalStaked).map(
                              ([symbol, value]) => {
                                if (value > 0 && symbol !== "$")
                                  return (
                                    <span className="migrated-row" key={symbol}>
                                      <span className="migrated-amount">
                                        {Number(value).toFixed(2)} {symbol}{" "}
                                      </span>
                                      <span className="migrated-arrow">→</span>{" "}
                                      <span className="migrated-symbol">
                                        e{symbol}
                                      </span>
                                      <br />
                                    </span>
                                  );
                                else return "";
                              }
                            )}
                          </div>
                        </span>
                        <span className={`right logo claimed`}>
                          <img
                            className={cabinet + "-logo"}
                            src={VICTIM_LOGOS[cabinet]}
                            alt=""
                            onClick={() =>
                              history.push(
                                `/${cabinet.replace(
                                  /[A-Z]+(?![a-z])|[A-Z]/g,
                                  ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
                                )}`
                              )
                            }
                          />
                        </span>
                      </div>
                    );
                  })}
                  <p
                    style={{
                      fontSize: "80%",
                      textAlign: "left",
                      margin: "15% 0 0",
                    }}
                  >
                    My total:
                    {totalUserTvl !== undefined ? (
                      <span
                        style={{
                          marginRight: "5%",
                          float: "right",
                          fontWeight: "bold",
                        }}
                      >
                        ${" "}
                        {Number(totalUserTvl).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    ) : (
                      <span
                        style={{
                          marginRight: "5%",
                          float: "right",
                          fontWeight: "bold",
                        }}
                      >
                        loading
                        <span className="tvl-info-loader-animation">...</span>
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <Button variant={"rectangleLarge"} onClick={toggleConnect}>
          CONNECT WALLET
        </Button>
      )}
    </div>
  );
};
