import React, { ReactNode, useEffect, useReducer } from "react";
import { useBlockNumber } from "@usedapp/core";
import { EtherPriceContext } from "./context";
import { apiCall, fetchEtherPrice } from "./fetchEtherPrice";
import { etherPriceReducer } from "./reducer";

interface Props {
  children: ReactNode;
}

export function EtherPriceProvider({ children }: Props) {
  const blockNumber = useBlockNumber();
  const [state, dispatch] = useReducer(etherPriceReducer, { price: undefined });

  useEffect(() => {
    let isMounted = true; // note mutable flag

    fetchEtherPrice(apiCall)
      .then((data) => {
        if (isMounted) dispatch({ type: "priceFetched", price: data.price });
      })
      .catch((error) => dispatch({ type: "fetchError", error }));
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [blockNumber]);

  return (
    <EtherPriceContext.Provider value={state}>
      {children}
    </EtherPriceContext.Provider>
  );
}
