export enum AppState {
  LOADING_ARCADE,
  LOADED_ARCADE_ON,
  LOADING_ARCADE_OFF,
  LOADING_CABINETS,
  LOADED_CABINETS,
  DONE,
}

export function appStateReducer(state, action) {
  switch (action.type) {
    case 1:
      return AppState.LOADED_ARCADE_ON;
    case 2:
      return AppState.LOADING_ARCADE_OFF;
    case 3:
      return AppState.LOADING_CABINETS;
    case 4:
      return AppState.LOADED_CABINETS;
    case 5:
      return AppState.DONE;
    case 0:
    default:
      return AppState.LOADING_ARCADE;
  }
}

export function cabinetStateReducer(state, action) {
  return { ...state, [action.type]: true };
}
