import { COVALENT_KEY, COVALENT_URL } from "../config/getApplicationConfig";
import axios from "axios";
import {
  ADAM,
  BTCPLUS,
  Convex,
  DTOP,
  Gutta,
  HUUB,
  Jesse,
  Lion,
  MUG,
  Potato,
  SNXDebt,
  sushiHOUSE,
  USDAPY,
  USDPLUS,
  WBTCAPY,
  WEB3,
} from "../config/victimMeta";

export const WETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

const DEFAULT_PRICES = {
  [USDAPY]: 624.94,
  [WBTCAPY]: 752.34,
  [USDPLUS]: 1,
  [MUG]: 898.27,
  [WEB3]: 7.24,
  [Gutta]: 3.259331,
  [Potato]: 6.51536291,
  [Convex]: 2.28222818,
  [Jesse]: 6.33585078,
  [DTOP]: 1.06290089,
  [SNXDebt]: 0.161695851,
  [Lion]: 0.00254587499,
  [ADAM]: 0.00424267213,
  [sushiHOUSE]: 15.06,
  [HUUB]: 6.65633449,
  [BTCPLUS]: 202158,
};

const USE_PRICE_DEFAULTS = [USDPLUS, BTCPLUS];

export const getPriceData = async (addressArr) => {
  const dt = new Date();
  const formatted = dt.toISOString().split("T")[0];
  const url = [
    COVALENT_URL,
    "/pricing/historical_by_addresses_v2/1/usd/",
    addressArr.join(","),
    "/?from=",
    formatted,
    "&key=",
    COVALENT_KEY,
  ].join("");
  try {
    const response = await axios.get(url);
    return response.data.data.reduce(
      (tokenPrices, { contract_address, prices }) => {
        const newTokenPrices = tokenPrices;
        const noPriceExists = !tokenPrices[contract_address];
        const newPriceBiggerThanOldPrice =
          prices[0]?.price > tokenPrices[contract_address];
        const usePriceDefault = USE_PRICE_DEFAULTS.includes(
          contract_address.toLowerCase()
        );
        if ((noPriceExists || newPriceBiggerThanOldPrice) && !usePriceDefault) {
          newTokenPrices[contract_address] = prices[0]?.price ?? 0;
        }
        return newTokenPrices;
      },
      DEFAULT_PRICES
    );
  } catch (e) {
    return [];
  }
};
