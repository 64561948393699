import "../../GamePages/Fomo/styles.scss";
import "../../GamePages/BullRun/styles.scss";
import "../../GamePages/WhaleMariner/styles.scss";
import "../../GamePages/DefiInvaders/styles.scss";
import "../../GamePages/CookingCrypto/styles.scss";
import "../../GamePages/HypedApes/styles.scss";

import bullRunCabinetPreview from "../../../assets/preview/bullRunCabinetPreview.png";
import cookingCryptoCabinetPreview from "../../../assets/preview/cookingCryptoCabinetPreview.png";
import defiInvadersCabinetPreview from "../../../assets/preview/defiInvadersCabinetPreview.png";
import fomoCabinetPreview from "../../../assets/preview/fomoCabinetPreview.png";
import hypedApesCabinetPreview from "../../../assets/preview/hypedApesCabinetPreview.png";
import whaleMarinerCabinetPreview from "../../../assets/preview/whaleMarinerCabinetPreview.png";

import bullRunCabinet from "../../../assets/fast/cabinet/bullRunCabinet.webm";
import fomoCabinet from "../../../assets/fast/cabinet/fomoCabinet.webm";
import defiInvadersCabinet from "../../../assets/fast/cabinet/defiInvadersCabinet.webm";
import cookingCryptoCabinet from "../../../assets/fast/cabinet/cookingCryptoCabinet.webm";
import hypedApesCabinet from "../../../assets/fast/cabinet/hypedApesCabinet.webm";
import whaleMarinerCabinet from "../../../assets/fast/cabinet/whaleMarinerCabinet.webm";

import bullRunAudio from "../../../assets/audio/bullRunAudio.mp3";
import fomoAudio from "../../../assets/audio/fomoAudio.mp3";
import defiInvadersAudio from "../../../assets/audio/defiInvadersAudio.mp3";
import cookingCryptoAudio from "../../../assets/audio/cookingCryptoAudio.mp3";
import hypedApesAudio from "../../../assets/audio/hypedApesAudio.mp3";
import whaleMarinerAudio from "../../../assets/audio/whaleMarinerAudio.mp3";

import slowBullRunCabinet from "../../../assets/slow/cabinet/slowBullRunCabinet.webm";
import slowFomoCabinet from "../../../assets/slow/cabinet/slowFomoCabinet.webm";
import slowDefiInvadersCabinet from "../../../assets/slow/cabinet/slowDefiInvadersCabinet.webm";
import slowCookingCryptoCabinet from "../../../assets/slow/cabinet/slowCookingCryptoCabinet.webm";
import slowHypedApesCabinet from "../../../assets/slow/cabinet/slowHypedApesCabinet.webm";
import slowWhaleMarinerCabinet from "../../../assets/slow/cabinet/slowWhaleMarinerCabinet.webm";
import React, { useRef } from "react";
import { NetworkSpeed, useNetworkSpeed } from "../../../misc/useNetworkSpeed";

const PREVIEWS = {
  bullRunCabinetPreview: bullRunCabinetPreview,
  cookingCryptoCabinetPreview: cookingCryptoCabinetPreview,
  defiInvadersCabinetPreview: defiInvadersCabinetPreview,
  fomoCabinetPreview: fomoCabinetPreview,
  hypedApesCabinetPreview: hypedApesCabinetPreview,
  whaleMarinerCabinetPreview: whaleMarinerCabinetPreview,
};

const SLOW_VIDEOS = {
  bullRunCabinet: slowBullRunCabinet,
  fomoCabinet: slowFomoCabinet,
  defiInvadersCabinet: slowDefiInvadersCabinet,
  cookingCryptoCabinet: slowCookingCryptoCabinet,
  hypedApesCabinet: slowHypedApesCabinet,
  whaleMarinerCabinet: slowWhaleMarinerCabinet,
};

const VIDEOS = {
  bullRunCabinet,
  fomoCabinet,
  defiInvadersCabinet,
  cookingCryptoCabinet,
  hypedApesCabinet,
  whaleMarinerCabinet,
};

const AUDIOS = {
  fomoAudio,
  bullRunAudio,
  whaleMarinerAudio,
  hypedApesAudio,
  defiInvadersAudio,
  cookingCryptoAudio,
};

export const Preload = ({ cabinet }: { cabinet: TCabinet }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const networkSpeed = useNetworkSpeed();
  const videos = networkSpeed !== NetworkSpeed.SLOW ? VIDEOS : SLOW_VIDEOS;

  return (
    <div style={{ position: "absolute", zIndex: -100 }}>
      <audio
        id={cabinet}
        src={AUDIOS[cabinet + "Audio"]}
        loop
        muted
        ref={audioRef}
      />
      <img
        className={"videoHidden"}
        src={PREVIEWS[cabinet + "CabinetPreview"]}
        alt=""
      />
      <video
        className={"videoHidden"}
        loop
        muted
        ref={videoRef}
        controls={false}
      >
        <source src={videos[cabinet + "Cabinet"]} />
      </video>
      <div className="preloadModalBackgrounds"></div>
      <span className={cabinet}>TESTING</span> {/*imports fonts*/}
    </div>
  );
};
