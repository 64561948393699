import * as Dialog from "@radix-ui/react-dialog";
import { BackButton } from "../../BackButton";
import { Text } from "../../Text";
import clsx from "clsx";
import { Claim } from "../../Claim";
import { Button } from "../../Button";
import React, { useCallback, useEffect, useState } from "react";
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { Contract } from "@ethersproject/contracts";
import { VICTIM_META } from "../../../../config/victimMeta";
import { useEthers } from "@usedapp/core";
import { getUserHasStaked } from "../../../../services/getUserHasStaked";
import { fireTx } from "../../../../services/fireTx";
import { getUserHasClaimed } from "../../../../services/getUserHasClaimed";
import { MuteIcon } from "../../Icons/MuteIcon";

export interface ClaimRewardProps {
  cabinet: TCabinet;
  open: boolean;
  hasClaimed?: boolean;
  proof?: string;
}

const CLAIM_ABI_FRAGMENT = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_lp",
        type: "address",
      },
      {
        internalType: "address",
        name: "_adapter",
        type: "address",
      },
    ],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ClaimReward = withRouter(
  ({
    cabinet,
    hasClaimed,
    proof,
  }: RouteComponentProps<any> & ClaimRewardProps) => {
    const { library } = useEthers();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [claimDisabled, setClaimDisabled] = useState(false);

    // const onClaimHandler = useCallback(async () => {
    //   const signer = library.getSigner()
    //   if (signer && !hasClaimed) {
    //     setClaimDisabled(true)
    //     const claimContract = new Contract(
    //       process.env.REACT_APP_CLAIM_CONTRACT,
    //       CLAIM_ABI_FRAGMENT,
    //       signer
    //       );

    //     try {
    //       await fireTx(
    //         claimContract,
    //         "claim",
    //         [
    //           proof,
    //           VICTIM_META[cabinet].adapter,
    //         ],
    //         cabinet
    //       );
    //       history.push("/")
    //     } catch (e) {
    //       setClaimDisabled(false)
    //     }

    //   }
    // }, [library, cabinet, proof, hasClaimed]);

    return (
      <>
        <Claim
          cabinet={cabinet}
          // disabled={claimDisabled}
          // hasClaimed={hasClaimed}
          // onClick={() => {
          //   if (!claimDisabled) {
          //     onClaimHandler();
          //   }
          // }}
        />
        {/* {hasClaimed && (
          <Button
            cabinet={cabinet}
            variant={
              (["defiInvaders", "fomo"].includes(cabinet)
                ? "squareSmall"
                : "square") + " close-reward"
            }
            onClick={() =>
              history.replace(
                [url.substring(0, url.lastIndexOf("/")), "/select"].join("")
              )
            }
            icon={<></>}
            leftIcon={<></>}
          >
            <Text cabinet={cabinet}>X</Text>
          </Button>
        )} */}
      </>
    );
  }
);
