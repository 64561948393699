import { Contract } from "@ethersproject/contracts";
import { ethers } from "@usedapp/core/node_modules/ethers";

const ABI_FRAGMENT = [
  {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "index",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "claimed",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
]

export const getUserHasClaimed = async (adapterOrIndex, provider, account) => {
  const contract = new Contract(
    process.env.REACT_APP_CLAIM_CONTRACT,
    ABI_FRAGMENT,
    provider
  );

  let index = adapterOrIndex
  try {
    const adapter = ethers.utils.getAddress(adapterOrIndex)
    index = await contract.index(adapter)
  } catch (e) {}
  const hasClaimed = await contract.claimed(account, index);

  return hasClaimed;
};
