import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navbar } from "../Navbar";
import { Actions } from "../Actions";
import "./gamePages.scss";

import bullRunCabinetPreview from "../../../assets/preview/bullRunCabinetPreview.png";
import cookingCryptoCabinetPreview from "../../../assets/preview/cookingCryptoCabinetPreview.png";
import defiInvadersCabinetPreview from "../../../assets/preview/defiInvadersCabinetPreview.png";
import fomoCabinetPreview from "../../../assets/preview/fomoCabinetPreview.png";
import hypedApesCabinetPreview from "../../../assets/preview/hypedApesCabinetPreview.png";
import whaleMarinerCabinetPreview from "../../../assets/preview/whaleMarinerCabinetPreview.png";

import fastBullRunCabinet from "../../../assets/fast/cabinet/bullRunCabinet.webm";
import fastFomoCabinet from "../../../assets/fast/cabinet/fomoCabinet.webm";
import fastDefiInvadersCabinet from "../../../assets/fast/cabinet/defiInvadersCabinet.webm";
import fastCookingCryptoCabinet from "../../../assets/fast/cabinet/cookingCryptoCabinet.webm";
import fastHypedApesCabinet from "../../../assets/fast/cabinet/hypedApesCabinet.webm";
import fastWhaleMarinerCabinet from "../../../assets/fast/cabinet/whaleMarinerCabinet.webm";

import bullRunAudio from "../../../assets/audio/bullRunAudio.mp3";
import fomoAudio from "../../../assets/audio/fomoAudio.mp3";
import defiInvadersAudio from "../../../assets/audio/defiInvadersAudio.mp3";
import cookingCryptoAudio from "../../../assets/audio/cookingCryptoAudio.mp3";
import hypedApesAudio from "../../../assets/audio/hypedApesAudio.mp3";
import whaleMarinerAudio from "../../../assets/audio/whaleMarinerAudio.mp3";

import slowBullRunCabinet from "../../../assets/slow/cabinet/slowBullRunCabinet.webm";
import slowFomoCabinet from "../../../assets/slow/cabinet/slowFomoCabinet.webm";
import slowDefiInvadersCabinet from "../../../assets/slow/cabinet/slowDefiInvadersCabinet.webm";
import slowCookingCryptoCabinet from "../../../assets/slow/cabinet/slowCookingCryptoCabinet.webm";
import slowHypedApesCabinet from "../../../assets/slow/cabinet/slowHypedApesCabinet.webm";
import slowWhaleMarinerCabinet from "../../../assets/slow/cabinet/slowWhaleMarinerCabinet.webm";
import { NetworkSpeed, useNetworkSpeed } from "../../../misc/useNetworkSpeed";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { liquidityMigration, VICTIM_META } from "../../../config/victimMeta";
import { useEthers } from "@usedapp/core";
import { getTokenData } from "../../../services/getTokenData";
import { getStakedAmount } from "../../../services/getStakedAmount";
import { Icon } from "../../../assets/icons";
import { Contract, ethers, utils } from "ethers";
import { liquidityMigrationAbi } from "../../../config/abis/liquidityMigration";
import { erc20Abi } from "../../../config/abis/erc20";
import { getUserHasClaimed } from "../../../services/getUserHasClaimed";
import { getExchangeAmountOut } from "../../../services/getExchangeAmountOut";

const PREVIEWS = {
  bullRunCabinetPreview: bullRunCabinetPreview,
  cookingCryptoCabinetPreview: cookingCryptoCabinetPreview,
  defiInvadersCabinetPreview: defiInvadersCabinetPreview,
  fomoCabinetPreview: fomoCabinetPreview,
  hypedApesCabinetPreview: hypedApesCabinetPreview,
  whaleMarinerCabinetPreview: whaleMarinerCabinetPreview,
};

const SLOW_VIDEOS = {
  bullRunCabinet: slowBullRunCabinet,
  fomoCabinet: slowFomoCabinet,
  defiInvadersCabinet: slowDefiInvadersCabinet,
  cookingCryptoCabinet: slowCookingCryptoCabinet,
  hypedApesCabinet: slowHypedApesCabinet,
  whaleMarinerCabinet: slowWhaleMarinerCabinet,
};

const VIDEOS = {
  bullRunCabinet: fastBullRunCabinet,
  fomoCabinet: fastFomoCabinet,
  defiInvadersCabinet: fastDefiInvadersCabinet,
  cookingCryptoCabinet: fastCookingCryptoCabinet,
  hypedApesCabinet: fastHypedApesCabinet,
  whaleMarinerCabinet: fastWhaleMarinerCabinet,
};

const AUDIOS = {
  fomoAudio,
  bullRunAudio,
  whaleMarinerAudio,
  hypedApesAudio,
  defiInvadersAudio,
  cookingCryptoAudio,
};

export interface GamePageProps {
  cabinet: TCabinet;
}
function GamePage({ cabinet }: GamePageProps) {
  const { account, library } = useEthers();
  const indexAddresses = Object.values(VICTIM_META[cabinet].indexes);
  const indexNames = Object.keys(VICTIM_META[cabinet].indexes);
  let { path } = useRouteMatch();
  const history = useHistory();
  const [audioReady, setAudioReady] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const networkSpeed = useNetworkSpeed();

  const videos = networkSpeed !== NetworkSpeed.SLOW ? VIDEOS : SLOW_VIDEOS;
  const cachedMuteState = localStorage.getItem("muteState");
  const [muteState, setMuteState] = useState(cachedMuteState ?? "unmuted");

  const [indexes, setIndexes] = useState<Index[]>([]);
  const [canClaim, setCanClaim] = useState(false);
  const [hasClaimed, setHasClaimed] = useState(false);

  useEffect(() => {
    document.body.className = `${cabinet}-body`;
  }, [cabinet]);

  const manageMuteState = () => {
    setMuteState(muteState === "muted" ? "unmuted" : "muted");
  };

  useEffect(() => {
    if (muteState === "unmuted") audioRef.current.play();
    localStorage.setItem(
      "muteState",
      muteState === "muted" ? "muted" : "unmuted"
    );
  }, [muteState]);

  const getHasClaimed = useCallback(async () => {
    if (account && library) {
      const claimed = await getUserHasClaimed(
        VICTIM_META[cabinet].adapter,
        library,
        account
      );
      setHasClaimed(claimed);
    }
  }, [account, library, cabinet]);

  const getStakedIndexes = useCallback(async () => {
    if (account) {
      const allStakedAmount = await getStakedAmount(account);
      allStakedAmount.forEach((stakedAmount) => {
        if (stakedAmount.amount > 0) setCanClaim(true);
        setIndexes((indexes) => {
          const newIndexes = [...indexes];
          const oldIndex = newIndexes.findIndex(
            (old) => old.address === stakedAmount.token.id
          );
          if (oldIndex >= 0) {
            newIndexes[oldIndex].staked = stakedAmount.amount;
          } else if (
            Object.values(VICTIM_META[cabinet].indexes).includes(
              stakedAmount.token.id
            )
          )
            newIndexes.push({
              address: stakedAmount.token.id,
              staked: stakedAmount.amount,
            });
          return newIndexes;
        });
      });
    }
  }, [account, cabinet]);

  const getIndex = useCallback(
    async (address, originalSymbol?: string) => {
      if (account && library) {
        const tokenData = await getTokenData(address, library, account);
        const exchangeAmountOut = await getExchangeAmountOut(
          address,
          library,
          VICTIM_META[cabinet].adapter,
          utils.parseEther(".001")
        );

        setIndexes((indexes) => {
          const newIndexes = [...indexes];
          const oldIndex = newIndexes.findIndex(
            (old) => old.address === address
          );
          if (oldIndex >= 0) {
            newIndexes[oldIndex].allowance = tokenData.allowance;
            newIndexes[oldIndex].balance = tokenData.balance;
            newIndexes[oldIndex].name = tokenData.name;
            newIndexes[oldIndex].decimals = tokenData.decimals;
            newIndexes[oldIndex].symbol = tokenData.symbol;
            newIndexes[oldIndex].icon = <Icon symbol={tokenData.symbol} />;
            newIndexes[oldIndex].originalSymbol = originalSymbol;
            newIndexes[oldIndex].exchange = exchangeAmountOut.exchange;
            newIndexes[oldIndex].amountOutPerMilliEth =
              exchangeAmountOut.amountOut;
          } else
            newIndexes.push({
              address,
              exchange: exchangeAmountOut.exchange,
              amountOutPerMilliEth: exchangeAmountOut.amountOut,
              icon: <Icon symbol={tokenData.symbol} />,
              ...tokenData,
              originalSymbol,
            });
          return newIndexes;
        });
      }
    },
    [account, cabinet, library, setIndexes]
  );

  const getIndexes = useCallback(() => {
    if (indexAddresses.length && account && library) {
      indexAddresses.forEach((address, i) => getIndex(address, indexNames[i]));
    }
  }, [account, library, indexAddresses, getIndex, indexNames]);

  useEffect(() => {
    let nftContract;
    let liquidityContract;
    let indexContracts;

    const CLAIM_EVENT_ABI = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "account",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "protocol",
            type: "uint256",
          },
        ],
        name: "Claimed",
        type: "event",
      },
    ];

    const loadIndexes = async () => {
      getHasClaimed();
      getIndexes();
      getStakedIndexes();

      nftContract = new Contract(
        process.env.REACT_APP_CLAIM_CONTRACT,
        CLAIM_EVENT_ABI,
        library
      );

      nftContract.on("Claimed", (by) => by === account && getHasClaimed());
    };

    if (indexAddresses.length && account && library) {
      liquidityContract = new Contract(
        liquidityMigration,
        liquidityMigrationAbi,
        library
      );

      liquidityContract.on("Staked", (adapter, strategy, amount, address) => {
        address === account &&
          indexAddresses.some((index) => index === strategy.toLowerCase()) &&
          getIndex(strategy.toLowerCase());
      });

      indexContracts = indexAddresses.map((index: string) => {
        const tokenContract = new Contract(index, erc20Abi, library);

        tokenContract.on("Approval", (owner, spender) => {
          if (owner !== liquidityMigration && spender === liquidityMigration)
            getIndex(index);
        });

        tokenContract.on("Transfer", (from, to) => {
          if (from === account && to === liquidityMigration) return;
          else if (to === account || from === account) getIndex(index);
        });

        return tokenContract;
      });
    }

    if (account && library) loadIndexes();

    return () => {
      if (account && library) {
        nftContract.removeAllListeners();
        liquidityContract.removeAllListeners();
        indexContracts.forEach((contract) => contract.removeAllListeners());
      }
    };
  }, [library, account]);

  const [volumeInterval, setVolumeInterval] = useState(null);

  useEffect(() => {
    audioRef.current.muted = muteState === "muted";
    audioRef.current.volume = 0;
    if (audioReady && !audioRef.current.muted) {
      const interval = setInterval(() => {
        try {
          if (audioRef.current.volume > 0.1) clearInterval(interval);
          else {
            audioRef.current.volume += 0.01;
          }
        } catch (e) {
          clearInterval(interval);
        }
      }, 100);
      setVolumeInterval(interval);
    } else {
      clearInterval(volumeInterval);
    }
  }, [audioReady, muteState]);

  useEffect(() => {
    const currentAudio = audioRef.current;

    const playAudio = async () => {
      try {
        audioRef.current.play();
      } catch (e) {
        setMuteState("muted");
      }
    };

    if (videoReady) {
      videoRef.current.play();
      playAudio();
    }

    return () => {
      if (currentAudio) currentAudio.pause();
    };
  }, [videoReady, cabinet]);

  useEffect(() => {
    const returnToMain = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        history.push("/");
      }
    };
    document.addEventListener("keydown", returnToMain);

    return () => document.removeEventListener("keydown", returnToMain);
  }, [history]);

  const [videoScale, setVideoScale] = useState({
    xOffset: 0,
    yOffset: 0,
    scale: 0,
  });

  useEffect(() => {
    const video = { width: 1920, height: 1080 };

    function handleResize() {
      const windowWidth = Math.min(
        window.visualViewport.width,
        window.screen.width,
        window.innerWidth
      );
      const windowHeight = Math.min(
        window.visualViewport.height,
        window.screen.height,
        window.innerHeight
      );

      const mobile =
        windowWidth < windowHeight &&
        windowWidth <= 1100 &&
        windowHeight <= 1100;
      const ultraWide = windowWidth >= 2000 && windowHeight >= 1125;

      if (mobile) document.body.classList.add("mobile");
      else document.body.classList.remove("mobile");

      if (ultraWide)
        document.querySelector(".v-navbar").classList.remove("fixed");
      else document.querySelector(".v-navbar").classList.add("fixed");

      document.body.style.width = `${mobile ? windowHeight : windowWidth}px`;
      document.body.style.height = `${mobile ? windowWidth : windowHeight}px`;

      const xScale = (mobile ? windowHeight : windowWidth) / video.width;
      const yScale = (mobile ? windowWidth : windowHeight) / video.height;

      let yOffset = 0;
      let xOffset = 0;
      let scale;

      if (ultraWide ? yScale > xScale : xScale > yScale) {
        scale = xScale;
        yOffset =
          ((mobile ? windowWidth : windowHeight) - video.height * scale) / 2;
      } else {
        scale = yScale;
        xOffset =
          ((mobile ? windowHeight : windowWidth) - video.width * scale) / 2;
      }

      document.body.style.fontSize = `${25 * scale}px`;

      setVideoScale({
        xOffset,
        yOffset,
        scale,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <audio
        preload="auto"
        id={cabinet}
        src={AUDIOS[cabinet + "Audio"]}
        loop
        muted={muteState === "muted"}
        ref={audioRef}
        onCanPlayThrough={() => {
          setAudioReady(true);
        }}
      />
      <img
        className="backgroundCover"
        src={PREVIEWS[cabinet + "CabinetPreview"]}
        alt=""
      />
      <div className="frame-container">
        <img
          className={videoReady ? "videoHidden" : "video"}
          src={PREVIEWS[cabinet + "CabinetPreview"]}
          alt=""
        />
        <video
          preload="auto"
          className={videoReady ? "video" : "videoHidden"}
          autoPlay
          loop
          muted={muteState === "muted"}
          ref={videoRef}
          controls={false}
          onCanPlayThrough={() => {
            setVideoReady(true);
          }}
        >
          <source src={videos[cabinet + "Cabinet"]} />
        </video>
      </div>
      <div className={cabinet}>
        <Navbar
          scale={videoScale.scale}
          yOffset={videoScale.yOffset}
          xOffset={videoScale.xOffset}
          cabinet={cabinet}
          onMuteClick={manageMuteState}
          muted={muteState === "muted"}
          canClaim={canClaim}
          hasClaimed={hasClaimed}
          canBuy={indexes.some((index) => index.exchange)}
        />
        <Switch>
          <Route path={`${path}`}>
            <Actions
              open={true}
              cabinet={cabinet}
              indexes={indexes}
              canClaim={canClaim}
              hasClaimed={hasClaimed}
            />
          </Route>
          {/* <Route exact path={`${path}/`}>
            <Redirect to={`${path}/select`} />
          </Route> */}
        </Switch>
      </div>
    </>
  );
}

export default GamePage;
