import axios from "axios";

export const getStakedAmount = async (address) => {
  const url =
    "https://api.thegraph.com/subgraphs/name/ensofinance/enso-liquidity-migration";
  const payload = {
    query: `
      query GetStakedTokensForUser {
        user(id: "${address.toLowerCase()}") {
          stakedTokens {
            token {
              id
              decimals
            }
            amount
          }
        }
      }
    `,
  };

  try {
    const response = await axios.post(url, payload);
    return response.data.data.user.stakedTokens;
  } catch (e) {
    return [];
  }
};
