import React from "react";
import "./text.scss";
export const Text = ({ children, ...other }) => {
  return (
    <span className={"cookingCrypto-rainbow-text"} {...other}>
      <span className={"cookingCrypto-rainbow-text-border"}>{children}</span>
      <span className={"cookingCrypto-rainbow-text-wrapper text"}>
        {children}
      </span>
    </span>
  );
};
