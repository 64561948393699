import { Button } from "../../common/Button";
import { ReactComponent as MuteIcon } from "../assets/icons/mute.svg";
import { ReactComponent as UnmuteIcon } from "../assets/icons/unmute.svg";
import { ReactComponent as MasterIcon } from "../assets/icons/master.svg";
import React from "react";
import { ReactComponent as MoreIcon } from "../assets/icons/more.svg";
import { InfoDropdown } from "../../common/InfoDropdown";
import { TVLDropdown } from "./TVLDropdown/TVLDropdown";
import _map from "lodash/map";
import { VICTIM_META } from "../../../config/victimMeta";

interface NavProps {
  account: string;
  setConnectDialogOpen: Function;
  connectDialogOpen: boolean;
  onMuteClick: () => void;
  muted: boolean;
  userTvlInfo?: any[];
  claimedCabinets?: any[];
  masterClaimed?: boolean;
  onMasterShow: () => void;
  totalUserTvl?: 0;
  scale: number;
  yOffset: number;
  xOffset: number;
  style?: {};
}
export const Nav = ({
  account,
  setConnectDialogOpen,
  onMuteClick,
  muted,
  userTvlInfo,
  claimedCabinets,
  masterClaimed,
  onMasterShow,
  totalUserTvl,
  scale,
  xOffset,
  yOffset,
  style,
}: NavProps) => {
  const toggleConnect = () => {
    !account && setConnectDialogOpen(true);
  };

  const hasStaked = userTvlInfo
    .map((tvlInfo) => Object.values(tvlInfo.totalStaked))
    .flat()
    .some((value) => value > 0);

  return (
    <ul
      className={"v-main-nav"}
      style={{
        top: 25 * scale + yOffset,
        right: 20 * scale + xOffset,
        columnGap: 10 * scale,
        ...style,
      }}
    >
      <li>
        <a
          href="https://leaderboard.vampire.enso.finance/"
          className="leaderboard-button v-button rectangleLarge"
        >
          Leaderboard
        </a>
      </li>
      {account && hasStaked && (
        <li>
          <a
            href="https://enso.finance/"
            className="claim-button v-button rectangleLarge"
          >
            Claim on Enso
          </a>
        </li>
      )}
      <li>
        <Button variant={"square"} onClick={onMasterShow}>
          <MasterIcon style={{ transform: "scale(1.3)" }} />
        </Button>
      </li>
      <li>
        <TVLDropdown
          style={{
            borderRadius: 25 * scale,
            width: 485 * scale,
            fontSize: 27 * scale,
          }}
          hasStaked={hasStaked}
          userTvlInfo={userTvlInfo}
          claimedCabinets={claimedCabinets}
          totalUserTvl={totalUserTvl}
          account={account}
          toggleConnect={toggleConnect}
        />
      </li>
      <li>
        <Button variant={"square"} onClick={onMuteClick}>
          {muted ? <MuteIcon /> : <UnmuteIcon />}
        </Button>
      </li>
      <li>
        <InfoDropdown
          style={{
            width: 150 * scale,
            fontSize: 25 * scale,
            padding: 10 * scale,
            borderRadius: 25 * scale,
            right: 0,
            top: 70 * scale,
          }}
          links={[
            { title: "Docs", url: "https://docs.enso.finance/" },
            { title: "Github", url: "https://github.com/EnsoFinance" },
            { title: "Twitter", url: "https://twitter.com/EnsoFinance" },
            {
              title: "Discord",
              url: "https://discord.com/invite/enso-finance",
            },
            { title: "Terms", url: "https://enso.finance/terms" },
          ]}
          cabinet={"main"}
          moreIcon={<MoreIcon />}
        />
      </li>
    </ul>
  );
};
