import _reduce from "lodash/reduce";
export const liquidityMigration =
  process.env.REACT_APP_LIQUIDITY_MIGRATION_CONTRACT;
export const tokenSetsAdapter = process.env.REACT_APP_TOKEN_SET_ADAPTER;
export const indexedAdapter = process.env.REACT_APP_INDEXED_ADAPTER;
export const pieDaoAdapter = process.env.REACT_APP_PIE_DAO_ADAPTER;
export const powerPoolAdapter = process.env.REACT_APP_POWER_POOL_ADAPTER;
export const indexCoopAdapter = process.env.REACT_APP_INDEX_COOP_ADAPTER;
export const dHedgeAdapter = process.env.REACT_APP_DHEDGE_ADAPTER;

// Tokensets Indexes:
export const USDAPY = "0x23687d9d40f9ecc86e7666dddb820e700f954526";
export const WBTCAPY = "0xf059afa5239ed6463a00fc06a447c14fe26406e1";
export const SCIFI = "0xfdc4a3fc36df16a78edcaf1b837d3acaaedb2cb4";
export const sushiHOUSE = "0x7b18913D945242A9c313573E6c99064cd940c6aF";
export const NFTI = "0xe5feeaC09D36B18b3FA757E5Cf3F8dA6B8e27F4C";
export const MUG = "0xFE05B972EAb7761B60b4A14558EAC3Fef78F306A";
export const HUUB = "0xB1F66BC5867A329cFd0465A506c4ad96e6Be1aDC";
export const TGF = "0xa188DA64fc4e212Cda65bD3406e0ce03a5323560";
export const CMI = "0xBbA8120b355bC70E771F28e151a141A126843CdF";
export const WEB3 = "0xe8e8486228753E01Dbc222dA262Aa706Bd67e601";

export const tokenSetsIndexes = {
  USDAPY,
  SCIFI,
  WBTCAPY,
  sushiHOUSE,
  NFTI,
  MUG,
  HUUB,
  CMI,
  WEB3,
};

// IndexCoop Indexes:
export const DPI = "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b";
export const DATA = "0x33d63ba1e57e54779f7ddaeaa7109349344cf5f1";
export const ETH2xFLI = "0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd";
export const BTC2xFLI = "0x0b498ff89709d3838a063f1dfa463091f9801c2b";
export const BED = "0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6";
export const MVI = "0x72e364f2abdc788b7e918bc238b21f109cd634d7";
export const indexCoopIndexes = {
  DPI,
  "ETH2x-FLI": ETH2xFLI,
  "BTC2x-FLI": BTC2xFLI,
  MVI,
  BED,
  DATA,
};

// DHedge Indexes:
export const DTOP = "0x0f4c00139602ab502bc7c1c0e71d6cb72a9fb0e7";
export const Convex = "0x63ae7457b8be660daaf308a07db6bccb733b92df";
export const Jesse = "0xd076b9865feb49A43Aa38c06b0432dF6b6cBCA9E";
export const SNXDebt = "0x3A4851597F36F459b58e65C55c8f3a8710313Fc7";
export const Gutta = "0x391603b1C3b03A0133AD82E91692790e58f73570";
export const Potato = "0x907FeB27f8cc5b003Db7e62dfc2f9B01ce3FADd6";
export const Lion = "0x3781eA00ECBE98d1550806C1213FC7FEb4F88a42";
export const ADAM = "0x0ac1DBa8252240589266194F9C27a42229E84B19";
export const dHedgeIndexes = {
  DTOP,
  Convex,
  Jesse,
  Gutta,
  Potato,
  SNXDebt,
  Lion,
  ADAM,
};

// Indexed Indexes:
export const DEGEN = "0x126c121f99e1e211df2e5f8de2d96fa36647c855";
export const ORCL5 = "0xd6cb2adf47655b1babddc214d79257348cbc39a7";
export const NFTP = "0x68bb81b3f67f7aab5fd1390ecb0b8e1a806f2465";
export const indexedIndexes = {
  DEGEN,
  ORCL5,
  NFTP,
};

// PowerPool Indexes:
export const PIPT = "0x26607aC599266b21d13c7aCF7942c7701a8b699c";
export const YETI = "0xb4bebD34f6DaaFd808f73De0d10235a92Fbb6c3D";
export const YLA = "0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B";
export const powerPoolIndexes = {
  PIPT,
  YETI,
  YLA,
};

// PieDao Indexes:
export const USDPLUS = "0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e";
export const BTCPLUS = "0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd";
export const DEFIS = "0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c";
export const pieDaoIndexes = {
  "USD++": USDPLUS,
  "BTC++": BTCPLUS,
  "DEFI+S": DEFIS,
};

const vm: VictimMeta = {
  bullRun: {
    victim: "indexed",
    indexes: indexedIndexes,
    adapter: indexedAdapter,
  },
  cookingCrypto: {
    victim: "pieDao",
    indexes: pieDaoIndexes,
    adapter: pieDaoAdapter,
  },
  defiInvaders: {
    victim: "dHedge",
    indexes: dHedgeIndexes,
    adapter: dHedgeAdapter,
  },
  hypedApes: {
    victim: "tokenSets",
    indexes: tokenSetsIndexes,
    adapter: tokenSetsAdapter,
  },
  whaleMariner: {
    victim: "powerPool",
    indexes: powerPoolIndexes,
    adapter: powerPoolAdapter,
  },
  fomo: {
    victim: "indexCoop",
    indexes: indexCoopIndexes,
    adapter: indexCoopAdapter,
  },
};

export const VICTIM_META: VictimMeta = _reduce(
  vm,
  (mem, metaInstance, metaKey) => {
    const { indexes } = metaInstance;
    const newIndexes = _reduce(
      indexes,
      (m, v, k) => ({
        ...m,
        [k]: v.toLowerCase(),
      }),
      {} as { [x: string]: string[] }
    );
    return { ...mem, [metaKey]: { ...metaInstance, indexes: newIndexes } };
  },
  {}
);
