import { ReactComponent as WhaleMarinerMoreIcon } from "../../GamePages/WhaleMariner/assets/icons/more.svg";
import { ReactComponent as FomoMoreIcon } from "../../GamePages/Fomo/assets/icons/more.svg";
import { ReactComponent as BullRunMoreIcon } from "../../GamePages/BullRun/assets/icons/more.svg";
import { ReactComponent as CookingCryptoMoreIcon } from "../../GamePages/CookingCrypto/assets/icons/more.svg";
import { ReactComponent as DefiInvadersMoreIcon } from "../../GamePages/DefiInvaders/assets/icons/more.svg";
import { ReactComponent as HypedApesMoreIcon } from "../../GamePages/HypedApes/assets/icons/more.svg";

export const MoreIcon = ({ cabinet }) => {
  switch (cabinet) {
    case "cookingCrypto":
      return <CookingCryptoMoreIcon />;
    case "hypedApes":
      return <HypedApesMoreIcon />;
    case "fomo":
      return <FomoMoreIcon />;
    case "bullRun":
      return <BullRunMoreIcon />;
    case "whaleMariner":
      return <WhaleMarinerMoreIcon />;
    case "defiInvaders":
      return <DefiInvadersMoreIcon />;

    default:
      return <span></span>;
  }
};
