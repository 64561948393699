import { memo, useEffect, useRef, useState } from "react";

import bullRunOn from "../../../assets/fast/on/bullRunOn.webm";
import fomoOn from "../../../assets/fast/on/fomoOn.webm";
import defiInvadersOn from "../../../assets/fast/on/defiInvadersOn.webm";
import cookingCryptoOn from "../../../assets/fast/on/cookingCryptoOn.webm";
import hypedApesOn from "../../../assets/fast/on/hypedApesOn.webm";
import whaleMarinerOn from "../../../assets/fast/on/whaleMarinerOn.webm";

import slowBullRunOn from "../../../assets/slow/on/slowBullRunOn.webm";
import slowFomoOn from "../../../assets/slow/on/slowFomoOn.webm";
import slowDefiInvadersOn from "../../../assets/slow/on/slowDefiInvadersOn.webm";
import slowCookingCryptoOn from "../../../assets/slow/on/slowCookingCryptoOn.webm";
import slowHypedApesOn from "../../../assets/slow/on/slowHypedApesOn.webm";
import slowWhaleMarinerOn from "../../../assets/slow/on/slowWhaleMarinerOn.webm";

import bullRunAudio from "../../../assets/audio/bullRunAudio.mp3";
import fomoAudio from "../../../assets/audio/fomoAudio.mp3";
import defiInvadersAudio from "../../../assets/audio/defiInvadersAudio.mp3";
import cookingCryptoAudio from "../../../assets/audio/cookingCryptoAudio.mp3";
import hypedApesAudio from "../../../assets/audio/hypedApesAudio.mp3";
import whaleMarinerAudio from "../../../assets/audio/whaleMarinerAudio.mp3";
import { NetworkSpeed, useNetworkSpeed } from "../../../misc/useNetworkSpeed";

import bullRunOnPreview from "../../../assets/preview/bullRunOnPreview.png";
import fomoOnPreview from "../../../assets/preview/fomoOnPreview.png";
import defiInvadersOnPreview from "../../../assets/preview/defiInvadersOnPreview.png";
import cookingCryptoOnPreview from "../../../assets/preview/cookingCryptoOnPreview.png";
import hypedApesOnPreview from "../../../assets/preview/hypedApesOnPreview.png";
import whaleMarinerOnPreview from "../../../assets/preview/whaleMarinerOnPreview.png";

const PREVIEWS = {
  bullRunOnPreview: bullRunOnPreview,
  fomoOnPreview: fomoOnPreview,
  defiInvadersOnPreview: defiInvadersOnPreview,
  cookingCryptoOnPreview: cookingCryptoOnPreview,
  hypedApesOnPreview: hypedApesOnPreview,
  whaleMarinerOnPreview: whaleMarinerOnPreview,
};

const SLOW_VIDEOS = {
  bullRunOn: slowBullRunOn,
  fomoOn: slowFomoOn,
  defiInvadersOn: slowDefiInvadersOn,
  cookingCryptoOn: slowCookingCryptoOn,
  hypedApesOn: slowHypedApesOn,
  whaleMarinerOn: slowWhaleMarinerOn,
};

const VIDEOS = {
  fomoOn,
  bullRunOn,
  whaleMarinerOn,
  hypedApesOn,
  defiInvadersOn,
  cookingCryptoOn,
};

const AUDIOS = {
  fomoAudio,
  bullRunAudio,
  whaleMarinerAudio,
  hypedApesAudio,
  defiInvadersAudio,
  cookingCryptoAudio,
};

export const Cabinet = memo(
  ({
    show,
    cabinet,
    onLoad,
    muted,
  }: {
    show: boolean;
    cabinet: TCabinet;
    onLoad: (cabinet) => void;
    muted: boolean;
  }) => {
    const [audioReady, setAudioReady] = useState(false);
    const [videoReady, setVideoReady] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const networkSpeed = useNetworkSpeed();
    const videos = networkSpeed !== NetworkSpeed.SLOW ? VIDEOS : SLOW_VIDEOS;

    useEffect(() => {
      if (audioReady && videoReady) {
        onLoad(cabinet);
      }
    }, [audioReady, videoReady, cabinet, onLoad]);

    useEffect(() => {
      if (audioReady) {
        audioRef.current.volume = muted ? 0 : 0.15;
        audioRef.current.muted = !show || muted;
      }
    }, [show, audioReady, muted]);

    useEffect(() => {
      if (videoReady && show) {
        videoRef.current.play();
        if (!muted) audioRef.current.play()
      }
    }, [onLoad, show, audioReady, videoReady, muted]);

    return (
      <div
        className={show ? "cabinet-visibility" : "cabinet-visibility invisible"}
      >
        <audio
          preload="auto"
          id={cabinet}
          src={AUDIOS[cabinet + "Audio"]}
          loop
          muted={muted}
          ref={audioRef}
          onCanPlayThrough={() => {
            setAudioReady(true);
          }}
        />
        <img
          className={show && videoReady ? "videoHidden video" : "videoHidden video"}
          src={PREVIEWS[cabinet + "OnPreview"]}
          alt=""
        />
        <video
          preload="auto"
          className={show && videoReady ? "videoHidden video" : "videoHidden"}
          autoPlay
          loop
          muted
          ref={videoRef}
          controls={false}
          onCanPlayThrough={() => {
            setVideoReady(true);
          }}
        >
          <source src={videos[cabinet + "On"]} />
        </video>
      </div>
    );
  }
);

export default Cabinet;
