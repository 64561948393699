import React, {
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from "react";
import clsx from "clsx";
import "./buttons.scss";

interface ButtonProps extends Partial<ButtonHTMLAttributes<HTMLButtonElement>> {
  cabinet?: string;
  variant: string;
  icon?: ReactNode;
  leftIcon?: ReactElement;
  children?: any;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
export const Button = ({
  cabinet,
  children,
  variant,
  icon,
  leftIcon,
  disabled,
  ...other
}: ButtonProps) => {
  return (
    <button
      {...other}
      className={clsx(
        `v-button ${cabinet}-button`,
        variant,
        disabled && "disabled"
      )}
    >
      {leftIcon && leftIcon}
      <span>{children}</span>
      {icon && icon}
    </button>
  );
};
