import { VICTIM_ICONS } from "../data";

interface TvlInfo {
  tvl: number;
  stakedCount: string;
}

interface TvlInfoProps {
  cabinet: TCabinet;
  tvlInfo?: TvlInfo;
  scale?: number;
  yOffset?: number;
  xOffset?: number;
}

export const TvlInfo = ({
  cabinet,
  tvlInfo,
  scale,
  yOffset,
  xOffset,
}: TvlInfoProps) => {
  return (
    <div
      className={"tvl-info"}
      style={{
        top: 294 * scale + yOffset,
        left: 813 * scale + xOffset,
        width: 285 * scale,
        height: 134 * scale,
        fontSize: 20 * scale,
        padding: 25 * scale,
        paddingLeft: 30 * scale,
      }}
    >
      <img
        style={{ height: 35 * scale }}
        className={cabinet === "whaleMariner" ? "whaleMariner-tvl-info" : ""}
        src={VICTIM_ICONS[cabinet]}
        alt=""
      />
      {tvlInfo ? (
        <div className={"figures"}>
          <div className={"headers"}>
            <div className={"tvl-header"}>TVL</div>
            <div className={"tvl-staked-header"}>Staked</div>
          </div>
          <div className={"values"}>
            <div className={"tvl-value"}>
              {tvlInfo.tvl !== undefined ? `$${tvlInfo.tvl.toLocaleString(undefined, {maximumFractionDigits: 2})}`: <span>loading<span className="tvl-info-loader-animation">...</span></span>}
            </div>
            <div className={"tvl-staked-count"}>{tvlInfo.stakedCount}</div>
          </div>
        </div>
      ) : (
        <div className="tvl-info-loader">
          Waiting for TVL<span className="tvl-info-loader-animation">...</span>
        </div>
      )}
    </div>
  );
};
