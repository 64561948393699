import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./notifications.scss";

interface NotificationsProps {
  children: React.ReactNode
}

export const Notifications = ({
  children,
}: NotificationsProps) => {
  const pathname = useLocation().pathname
  const [cabinet, setCabinet] = useState('main-page')

  useEffect(() => {
    const cabinets = ["bullRun", "cookingCrypto", "defiInvaders", "fomo", "hypedApes", "whaleMariner"]

    if (cabinets.some(substring => pathname.includes(substring))) {
      setCabinet(pathname.split('/')[1])
    } else (
      setCabinet('main-page')
    )
  }, [pathname])

  return (
    <div className={clsx("notifications", `${cabinet}`)}>
      {children}
    </div>
  );
};
