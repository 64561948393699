import { Text as CookingCryptoText } from "../../GamePages/CookingCrypto/Text";
import { Text as HypedApesText } from "../../GamePages/HypedApes/Text";
export const Text = ({ cabinet, children, ...other }) => {
  switch (cabinet) {
    case "cookingCrypto":
      return <CookingCryptoText {...other}>{children}</CookingCryptoText>;
    case "hypedApes":
      return <HypedApesText {...other}>{children}</HypedApesText>;
    default:
      return <span className={'text'} {...other}>{children}</span>;
  }
};
