import "./styles.scss";
import USDAPY from "./USDAPY.png";
import BCP from "./BCP.png";
import BED from "./BED.png";
import BTCPLUS from "./BTC++.png";
import BTC2xFLI from "./BTC2x-FLI.png";
import CC10 from "./CC10.png";
import DEFIPLUS from "./DEFI++.png";
import DEFIS from "./DEFI+S.png";
import DEFI5 from "./DEFI5.png";
import DEGEN from "./DEGEN.png";
import DPI from "./DPI.png";
import ETH2xFLI from "./ETH2x-FLI.png";
import MVI from "./MVI.png";
import NFTP from "./NFTP.png";
import ORCL5 from "./ORCL5.png";
import PLAY from "./PLAY.png";
import SCIFI from "./SCIFI.png";
import WBTCAPY from "./WBTCAPY.png";
import YPIE from "./YPIE.png";
import PIPT from "./PIPT.png";
import YETI from "./YETI.png";
import ASSY from "./ASSY.png";
import YLA from "./YLA.png";
import USDPLUS from "./USD++.png";
import Unknown from "./unknown.png";
import DHedge from "./DHEDGE.png";
import DATA from "./DATA.png";
import NFTI from "./NFTI.svg";
import CMI from "./CMI.png";
import WEB3 from "./WEB3.jpeg";
import MUG from "./MUGS.png";
import SUSHI from "./SUSHI.png";
import HUUB from "./HUUB.png";

export const Icon = ({ symbol }) => {
  let imgSrc;
  switch (symbol) {
    case "HUUB":
      imgSrc = HUUB;
      break;
    case "sushiHOUSE":
      imgSrc = SUSHI;
      break;
    case "MUG":
      imgSrc = MUG;
      break;
    case "WEB3":
      imgSrc = WEB3;
      break;
    case "CMI":
      imgSrc = CMI;
      break;
    case "NFTI":
      imgSrc = NFTI;
      break;
    case "DATA":
      imgSrc = DATA;
      break;
    case "DTOP":
    case "DHPT":
      imgSrc = DHedge;
      break;
    case "SCIFI":
      imgSrc = SCIFI;
      break;
    case "WBTCAPY":
      imgSrc = WBTCAPY;
      break;
    case "YPIE":
      imgSrc = YPIE;
      break;
    case "MVI":
      imgSrc = MVI;
      break;
    case "NFTP":
      imgSrc = NFTP;
      break;
    case "ORCL5":
      imgSrc = ORCL5;
      break;
    case "PLAY":
      imgSrc = PLAY;
      break;
    case "DEGEN":
      imgSrc = DEGEN;
      break;
    case "DPI":
      imgSrc = DPI;
      break;
    case "ETH2x-FLI":
      imgSrc = ETH2xFLI;
      break;
    case "USDAPY":
      imgSrc = USDAPY;
      break;
    case "BCP":
      imgSrc = BCP;
      break;
    case "BED":
      imgSrc = BED;
      break;
    case "BTC++":
      imgSrc = BTCPLUS;
      break;
    case "BTC2x-FLI":
      imgSrc = BTC2xFLI;
      break;
    case "CC10":
      imgSrc = CC10;
      break;
    case "DEFI++":
      imgSrc = DEFIPLUS;
      break;
    case "DEFI+S":
      imgSrc = DEFIS;
      break;
    case "DEFI5":
      imgSrc = DEFI5;
      break;
    case "PIPT":
      imgSrc = PIPT;
      break;
    case "YETI":
      imgSrc = YETI;
      break;
    case "ASSY":
      imgSrc = ASSY;
      break;
    case "YLA":
      imgSrc = YLA;
      break;
    case "USD++":
      imgSrc = USDPLUS;
      break;
    default:
      imgSrc = Unknown;
  }
  return (
    <img className={"token-icon"} src={imgSrc} alt={`${symbol}-logo.png`} />
  );
};
