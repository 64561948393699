import { ReactComponent as WhaleMarinerMuteIcon } from "../../GamePages/WhaleMariner/assets/icons/mute.svg";
import { ReactComponent as FomoMuteIcon } from "../../GamePages/Fomo/assets/icons/mute.svg";
import { ReactComponent as BullRunMuteIcon } from "../../GamePages/BullRun/assets/icons/mute.svg";
import { ReactComponent as CookingCryptoMuteIcon } from "../../GamePages/CookingCrypto/assets/icons/mute.svg";
import { ReactComponent as DefiInvadersMuteIcon } from "../../GamePages/DefiInvaders/assets/icons/mute.svg";
import { ReactComponent as HypedApesMuteIcon } from "../../GamePages/HypedApes/assets/icons/mute.svg";

export const MuteIcon = ({ cabinet }) => {
  switch (cabinet) {
    case "cookingCrypto":
      return <CookingCryptoMuteIcon />;
    case "hypedApes":
      return <HypedApesMuteIcon />;
    case "fomo":
      return <FomoMuteIcon />;
    case "bullRun":
      return <BullRunMuteIcon />;
    case "whaleMariner":
      return <WhaleMarinerMuteIcon />;
    case "defiInvaders":
      return <DefiInvadersMuteIcon />;
    default:
      return <span></span>;
  }
};
