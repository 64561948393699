import React from "react";
import "./claim.scss";
import clsx from "clsx";

import nftBullRun from "../../../assets/fast/nfts/bullRunTrophy.webm";
import nftFomo from "../../../assets/fast/nfts/fomoTrophy.webm";
import nftDefiInvaders from "../../../assets/fast/nfts/defiTrophy.webm";
import nftCookingCrypto from "../../../assets/fast/nfts/cookingCryptoTrophy.webm";
import nftHypedApes from "../../../assets/fast/nfts/hypedApesTrophy.webm";
import nftWhaleMariner from "../../../assets/fast/nfts/whaleMarinerTrophy.webm";

const NFT = {
  bullRun: nftBullRun,
  fomo: nftFomo,
  defiInvaders: nftDefiInvaders,
  cookingCrypto: nftCookingCrypto,
  hypedApes: nftHypedApes,
  whaleMariner: nftWhaleMariner,
};

export const Claim = ({ cabinet }) => (
  <div className={clsx("v-claim-reward", `${cabinet}-claim-reward`)}>
    <video preload="auto" autoPlay controls={false} loop>
      <source src={NFT[cabinet]} type="video/webm" />
    </video>
    {/* <div style={!hasClaimed && !disabled ? {cursor: "pointer"} : {}} onClick={onClick} className={"v-claim-reward-button"}/> */}
  </div>
);
