import { Contract } from "@ethersproject/contracts";
import { abstractAdapterAbi } from "../config/abis/abstractAdapter";
import { BigNumber } from "ethers";
import {
  DPI,
  BED,
  MVI,
  ETH2xFLI,
  PIPT,
  DEGEN,
  DATA,
  BTC2xFLI,
} from "../config/victimMeta";

const whiteListedTokens = [
  DPI,
  BED,
  MVI,
  ETH2xFLI,
  PIPT,
  DEGEN,
  DATA,
  BTC2xFLI,
];

const SUSHI = "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F";
const UNI_V2 = "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a";
const UNI_V3 = "0xE592427A0AEce92De3Edee1F18E0157C05861564";
export const EXCHANGES = [SUSHI, UNI_V2, UNI_V3];
export const EXCHANGE_NAMES = ["Sushi", "Uniswap V2", "Uniswap V3"];
export const getExchangeAmountOut = async (
  address,
  provider,
  adapter,
  amountIn
) => {
  const contract = new Contract(adapter, abstractAdapterAbi, provider);

  let exchangeAmountOut = {
    exchange: "",
    amountOut: BigNumber.from("0"),
    exchangeName: "",
  };

  if (whiteListedTokens.includes(address)) {
    for (const exchange of EXCHANGES) {
      let amountOut = BigNumber.from("0");
      try {
        amountOut = await contract.callStatic.getAmountOut(
          address,
          exchange,
          amountIn
        );
      } catch (e) {
        continue;
      }
      if (amountOut.gt(exchangeAmountOut.amountOut))
        exchangeAmountOut = {
          exchange,
          amountOut,
          exchangeName: EXCHANGE_NAMES[EXCHANGES.indexOf(exchange)],
        };
    }
  }

  return exchangeAmountOut;
};
