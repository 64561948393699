import { Contract } from "@ethersproject/contracts";
import { useEthers } from "@usedapp/core";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { fireTx } from "../../services/fireTx";

import intro from "../../assets/fast/masterCoin/1.webm";
import loop from "../../assets/fast/masterCoin/2.webm";
import outro from "../../assets/fast/masterCoin/3.webm";

import logo from "./assets/logo.svg";

export const MasterReward = ({
  masterClaimed,
  onOutroEnd,
}: {
  masterClaimed: boolean;
  onOutroEnd: () => void;
}) => {
  const videosArray = [intro, loop, outro];
  const introRef = useRef<HTMLVideoElement>(null);
  const loopRef = useRef<HTMLVideoElement>(null);
  const outroRef = useRef<HTMLVideoElement>(null);
  const [videoToPlay, setVideoToPlay] = useState("Intro");
  const [introLoaded, setIntroLoaded] = useState(false);
  const [loopLoaded, setLoopLoaded] = useState(false);
  const [outroLoaded, setOutroLoaded] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const { library } = useEthers();

  const CLAIM_ABI_FRAGMENT = useMemo(
    () => [
      {
        inputs: [],
        name: "master",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "max",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "claimed",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    []
  );

  const onClaimHandler = useCallback(async () => {
    const signer = library?.getSigner();
    if (signer) {
      const claimContract = new Contract(
        process.env.REACT_APP_CLAIM_CONTRACT,
        CLAIM_ABI_FRAGMENT,
        signer
      );

      try {
        await fireTx(claimContract, "master", [], "main");
        setVideoToPlay("LoopFinish");
      } catch (e) {}
    }
  }, [library, CLAIM_ABI_FRAGMENT]);

  useEffect(() => {
    const intro = introRef.current;
    const loop = loopRef.current;
    const outro = outroRef.current;

    const introEnd = () => {
      if (videoToPlay === "SkipLoop") setVideoToPlay("Outro");
      else setVideoToPlay("Loop");
    };

    const loopEnd = () => {
      if (videoToPlay === "LoopFinish") setVideoToPlay("Outro");
      else loop?.play();
    };

    if (videoToPlay === "Intro") intro?.play();
    if (videoToPlay === "Loop" || videoToPlay === "LoopFinish") loop?.play();
    if (videoToPlay === "Outro") outro?.play();

    intro?.addEventListener("ended", introEnd);
    loop?.addEventListener("ended", loopEnd);
    outro?.addEventListener("ended", onOutroEnd);

    if (loopLoaded) {
      setAllLoaded(true);
    }

    return () => {
      intro?.removeEventListener("ended", introEnd);
      loop?.removeEventListener("ended", loopEnd);
      outro?.removeEventListener("ended", onOutroEnd);
    };
  }, [
    videoToPlay,
    introLoaded,
    loopLoaded,
    outroLoaded,
    allLoaded,
    onOutroEnd,
  ]);

  return (
    <section
      className="master-reward"
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (videoToPlay === "Loop") {
          setVideoToPlay("LoopFinish");
        } else if (videoToPlay === "Intro") {
          setVideoToPlay("SkipLoop");
        }
      }}
    >
      <div className="master-reward__videos">
        <video
          ref={introRef}
          autoPlay={false}
          muted
          preload="auto"
          className={
            videoToPlay === "Intro" || videoToPlay === "SkipLoop"
              ? "videoHidden video"
              : "videoHidden"
          }
          controls={false}
          onCanPlayThrough={() => setIntroLoaded(true)}
        >
          <source src={videosArray[0]} type="video/webm" />
        </video>
        <video
          ref={loopRef}
          autoPlay={false}
          muted
          preload="auto"
          className={
            videoToPlay === "Loop" || videoToPlay === "LoopFinish"
              ? "videoHidden video"
              : "videoHidden"
          }
          controls={false}
          onCanPlayThrough={() => setLoopLoaded(true)}
        >
          <source src={videosArray[1]} type="video/webm" />
        </video>
        <video
          ref={outroRef}
          autoPlay={false}
          muted
          preload="auto"
          className={
            videoToPlay === "Outro" ? "videoHidden video" : "videoHidden"
          }
          controls={false}
          onCanPlayThrough={() => setOutroLoaded(true)}
        >
          <source src={videosArray[2]} type="video/webm" />
        </video>
      </div>
      {(videoToPlay === "Loop" || videoToPlay === "LoopFinish") && (
        <div
          className={
            (videoToPlay === "LoopFinish" ? "migration-text-hide" : "") +
            " migration-text"
          }
        >
          <img src={logo} alt="" />
          <h1>Vampire attack is now closed</h1>
          <p>
            All Indicies have been migrated to the Enso platform, you can claim
            your staked indicies on Enso here.
          </p>
          <div className="migration-button-container">
            <a
              href="https://ensofinance.medium.com/vampire-attack-completed-7d6a2becbd3e"
              className="v-button rectangleLarge migration-button"
            >
              Read Announcement
            </a>
            <a
              href="https://enso.finance/"
              className="v-button rectangleLarge migration-button launch-enso-button"
            >
              Launch Enso
            </a>
          </div>
        </div>
      )}
    </section>
  );
};
