import { useEffect, useState, memo } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import CoinbaseWalletIcon from "../assets/coinbase.png";
import MetaMaskIcon from "../assets/metamask.png";
import FortMaticIcon from "../assets/fortmatic.png";
import WalletConnectIcon from "../assets/wallet-connect.png";
import {
  BuyIndexes,
  ClaimReward,
  ConnectWallet,
  SelectIndexes,
  StakeIndexes,
} from "./actions";
import "./actions.scss";
import { GuardedRoute } from "../GuardedRoute/GuardedRoute";

export interface ActionsProps {
  open: boolean;
  cabinet?: TCabinet;
  indexes?: Index[];
  canClaim?: boolean;
  hasClaimed?: boolean;
}
export enum ProgressState {
  SELECT_INDEXES = "select",
  STAKE_INDEXES = "stake",
  BUY_INDEXES = "buy",
  CLAIM_REWARD = "reward",
  CONNECT_WALLET = "connect-wallet",
}

export const Actions = memo(
  ({ open, cabinet, indexes, canClaim, hasClaimed }: ActionsProps) => {
    const history = useHistory();
    const params = useParams<{ progress?: string }>();
    const [initialTimeLeft, setInitialTimeLeft] = useState(false);
    const [showAfterTimeLeft, setShowAfterTimeLeft] = useState(false);
    const inCabinet = history.location.pathname.includes(cabinet);
    const [showModal, setShowModal] = useState(false);
    const initialTimeDuration = 1000;
    const showAfterTimeDuration = 5000;
    const { url } = useRouteMatch();
    const parentPath = url.substring(0, url.lastIndexOf("/"));
    const [selectedIndexes, setSelectedIndexes] = useState<Index[]>([]);

    useEffect(() => {
      (
        document.querySelector(".frame-container") as HTMLDivElement
      ).style.filter = showModal || showAfterTimeLeft ? "blur(5px)" : "";
    }, [showAfterTimeLeft, showModal]);

    useEffect(() => {
      const initialTimer = setTimeout(() => {
        setInitialTimeLeft(true);
      }, initialTimeDuration);

      const showAfterTimer = setTimeout(() => {
        setShowAfterTimeLeft(true);
      }, showAfterTimeDuration);

      const handleWindowEvent = (event: Event) => {
        if (initialTimeLeft) setShowModal(true);
      };

      window.addEventListener("click", handleWindowEvent);
      window.addEventListener("keypress", handleWindowEvent);

      return () => {
        clearTimeout(initialTimer);
        clearTimeout(showAfterTimer);
        window.removeEventListener("click", handleWindowEvent);
        window.removeEventListener("keypress", handleWindowEvent);
      };
    }, [initialTimeLeft]);

    return (
      (showModal || showAfterTimeLeft) && (
        <Switch>
          {/* <Route path={`/:cabinet/connect-wallet`}>
            <ConnectWallet
              open={open}
              cabinet={cabinet ?? "main"}
              title={"Connect Wallet"}
              connections={[
                // @ts-ignore
                window.web3 && window.ethereum
                  ? {
                      name: "MetaMask",
                      icon: (
                        <img
                          src={MetaMaskIcon}
                          className="walletIcon"
                          alt="MetaMask Icon"
                        />
                      ),
                    }
                  : {
                      name: "Install MetaMask",
                      icon: (
                        <img
                          src={MetaMaskIcon}
                          className="walletIcon"
                          alt="MetaMask Icon"
                        />
                      ),
                      link: "https://metamask.io/",
                    },
                {
                  name: "Trust Wallet",
                  icon: (
                    <img
                      src={FortMaticIcon}
                      className="walletIcon"
                      alt="Trust Wallet Icon"
                    />
                  ),
                },
                {
                  name: "Coinbase Wallet",
                  icon: (
                    <img
                      src={CoinbaseWalletIcon}
                      className="walletIcon"
                      alt="Coinbase Wallet Icon"
                    />
                  ),
                },
                {
                  name: "Wallet Connect",
                  icon: (
                    <img
                      src={WalletConnectIcon}
                      className="walletIcon"
                      alt="WalletConnect Icon"
                    />
                  ),
                },
              ]}
            />
          </Route> */}
          {/* <GuardedRoute path={`/:cabinet/select`}>
            <SelectIndexes
              open={open}
              onSelect={(idxs: Index[]) => {
                setSelectedIndexes(idxs);
                history.push(parentPath + "/stake");
              }}
              cabinet={cabinet}
              indexes={indexes}
            />
          </GuardedRoute> */}
          {/* <GuardedRoute
            path={`/:cabinet/stake`}
            render={() =>
              selectedIndexes.length === 0 ? (
                history.replace(parentPath + "/select")
              ) : (
                <StakeIndexes
                  open={open}
                  cabinet={cabinet}
                  indexes={selectedIndexes}
                />
              )
            }
          /> */}
          {/* <GuardedRoute
            path={`/:cabinet/buy`}
            render={() => {
              return indexes.some(index => index.exchange) ? (
                <BuyIndexes
                open={open}
                cabinet={cabinet}
                indexes={indexes.filter((index) => index.exchange)}
                />
              ) : (
                history.replace(parentPath + "/select")
              )
            }}
          /> */}
          <GuardedRoute path={`/:cabinet`}>
            <ClaimReward cabinet={cabinet} open={open} />
          </GuardedRoute>
        </Switch>
      )
    );
  }
);
