import React, { ReactNode } from "react";
import { EtherPriceProvider } from "./etherPrice/provider";
import { DAppProvider } from "@usedapp/core";
import { IdProvider } from "@radix-ui/react-id";
import { getApplicationConfig } from "../config/getApplicationConfig";

interface Props {
  children: ReactNode;
}

export function Providers(props: Props) {
  const appConfig = getApplicationConfig();

  const config = {
    readOnlyChainId: appConfig.network.defaultChainId,
    readOnlyUrls: appConfig.network.urls,
  };

  return (
    <IdProvider>
      <DAppProvider config={config}>
        <EtherPriceProvider>{props.children}</EtherPriceProvider>
      </DAppProvider>
    </IdProvider>
  );
}
