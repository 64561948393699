import { MouseEventHandler, useState } from "react";
import "./navbar.scss";
import { Button } from "../Button";
import { Text } from "../Text";
import { MuteIcon } from "../Icons/MuteIcon";
import { UnmuteIcon } from "../Icons/UnmuteIcon";
import { InfoDropdown } from "../InfoDropdown";
import { MoreIcon } from "../Icons/MoreIcon";
import clsx from "clsx";
import { shortenAddress } from "../../../misc/utils";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { injected } from "../../../misc/connectors/connectors";

interface NavbarProps {
  cabinet: TCabinet;
  onMuteClick: MouseEventHandler<HTMLButtonElement>;
  muted: boolean;
  canClaim: boolean;
  hasClaimed: boolean;
  canBuy: boolean;
  scale: number;
  yOffset: number;
  xOffset: number;
}

export const Navbar = ({
  cabinet,
  onMuteClick,
  muted,
  canClaim,
  hasClaimed,
  canBuy,
  scale,
  xOffset,
  yOffset,
}: NavbarProps) => {
  const history = useHistory();
  const { account } = useEthers();
  const { url } = useRouteMatch();
  const iconSize = ["defiInvaders", "fomo"].includes(cabinet)
    ? "squareSmall"
    : "square";

  return (
    <ul
      className={clsx("v-navbar", `${cabinet}-v-navbar`)}
      style={{
        top: 25 * scale + yOffset,
        left: 15 * scale + xOffset,
        right: 15 * scale + xOffset,
      }}
    >
      <li className={clsx("v-navbar-left", `${cabinet}-v-navbar-left`)}>
        <Button
          cabinet={cabinet}
          variant={"rectangleSmall"}
          onClick={() => {
            history.push("/");
          }}
        >
          <Text cabinet={cabinet}>Back</Text>
        </Button>
        {/* {canBuy && (
          <Button
            variant={"rectangleLarge"}
            cabinet={cabinet}
            onClick={() => {
              history.push([url, "/buy"].join(""));
            }}
          >
            <Text cabinet={cabinet}>Buy & Stake</Text>
          </Button>
        )} */}
        {/* {canClaim && (
          <Button
            cabinet={cabinet}
            variant={"rectangleLarge"}
            onClick={() => {
              history.push([url, "/reward"].join(""));
            }}
          >
            <Text cabinet={cabinet}>{hasClaimed ? "My" : "Claim"} Reward</Text>
          </Button>
        )} */}
      </li>
      <li className={clsx("v-navbar-right", `${cabinet}-v-navbar-right`)}>
        {/* {!account && (
          <Button cabinet={cabinet} variant={"rectangleLarge"}>
            <Text cabinet={cabinet}>Connect Wallet</Text>
          </Button>
        )} */}

        {account && (
          <Button
            cabinet={cabinet}
            variant={"rectangleLarge"}
            icon={<></>}
            leftIcon={<></>}
          >
            <Text cabinet={cabinet}>{shortenAddress(account)}</Text>
          </Button>
        )}

        <div className={"v-navbar-right-icons"}>
          <Button
            cabinet={cabinet}
            variant={iconSize}
            icon={
              muted ? (
                <MuteIcon cabinet={cabinet} />
              ) : (
                <UnmuteIcon cabinet={cabinet} />
              )
            }
            onClick={onMuteClick}
            leftIcon={<></>}
          >
            {}
          </Button>
          <InfoDropdown
            moreIcon={<MoreIcon cabinet={cabinet} />}
            cabinet={cabinet}
            style={{
              width: 150 * scale,
              fontSize: 25 * scale,
              padding: 10 * scale,
              borderRadius: 25 * scale,
              right: 0,
              top: 60 * scale,
            }}
            links={[
              { title: "Docs", url: "https://docs.enso.finance/" },
              { title: "Github", url: "https://github.com/EnsoFinance" },
              { title: "Twitter", url: "https://twitter.com/EnsoFinance" },
              {
                title: "Discord",
                url: "https://discord.com/invite/enso-finance",
              },
              { title: "Terms", url: "https://enso.finance/terms" },
            ]}
          />
        </div>
      </li>
    </ul>
  );
};
