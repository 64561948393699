import React, { useEffect, useMemo, useState } from "react";
import "./buyIndex.scss";
import { ReactComponent as Eth } from "../assets/eth.svg";
import { ReactComponent as Pulse } from "../assets/pulse.svg";
import { ReactComponent as DownCaret } from "../assets/dCaret.svg";
import { ReactComponent as UpCaret } from "../assets/uCaret.svg";
import ReactDOM from "react-dom";

import { useSelect } from "downshift";
import { usePopper } from "react-popper";
import { NumberInput } from "../NumberInput";
import clsx from "clsx";
import { BigNumber, ethers, utils } from "ethers";
import { useEtherBalance, useEthers } from "@usedapp/core";
import { getPriceData, WETH } from "../../../services/getPriceData";
import { useNumberInput } from "../../../hooks/useNumberInput";
import { useExchangeAmountOut } from "../../../hooks/useExchangeAmountOut";
import { VICTIM_META } from "../../../config/victimMeta";

const itemToString = (item) => {
  return item?.symbol ?? "";
};

export enum PriceImpact {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export const BuyIndex = ({ indexes, cabinet, onChange, setSlippageOk }) => {
  const { account } = useEthers();
  const balance = useEtherBalance(account);
  const [tokenAmountInWei, setTokenAmountInWei] = useState("0");
  const [ethAmountInWei, setEthAmountInWei] = useState("0");
  const [ethPerIndexToken, setEthPerIndexToken] = useState(BigNumber.from(0));
  const [invertExchange, setInvertExchange] = useState(true);
  const [ethPrice, setEthPrice] = useState(Number(0));
  const [referenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: indexes,
    itemToString,
    initialSelectedItem: indexes[0],
  });

  const [ethAmount, setEthAmount] = useNumberInput(18);
  const [tokenAmount, setTokenAmount] = useNumberInput(selectedItem.decimals);

  const newAmountOut = useExchangeAmountOut(
    selectedItem?.address,
    ethAmountInWei,
    VICTIM_META[cabinet].adapter
  );

  const slippageValues = useMemo(() => {
    if (!!newAmountOut?.exchange && +ethAmountInWei > 0) {
      const amountPerEthWithNoSlippage = BigNumber.from(
        selectedItem.amountOutPerMilliEth
      ).mul(1000);
      const amountPerEthAfterSlippage = newAmountOut.amountOut
        .mul(BigNumber.from(10).pow(18))
        .div(ethAmountInWei);
      const slippage = (
        100 -
        +amountPerEthAfterSlippage.mul(100) / +amountPerEthWithNoSlippage
      ).toFixed(2);
      const priceImpact: PriceImpact =
        +slippage <= 1
          ? PriceImpact.LOW
          : +slippage <= 3
          ? PriceImpact.MEDIUM
          : PriceImpact.HIGH;
      setSlippageOk(+slippage <= 10);

      return { slippage, priceImpact };
    }
    return null;
  }, [ethAmountInWei, newAmountOut, selectedItem, setSlippageOk]);

  useEffect(() => {
    const getEthPrice = async () => {
      const priceData = await getPriceData([WETH]);
      setEthPrice(priceData[WETH.toLowerCase()]);
    };
    getEthPrice();
  }, []);

  useEffect(() => {
    const result = {
      ...selectedItem,
      minAmountOut: BigNumber.from(tokenAmountInWei)
        .mul(995)
        .div(1000)
        .toString(), // 0.5% slippage
      amountEthIn: ethAmountInWei,
    };
    onChange(result);
  }, [tokenAmountInWei, onChange, ethAmountInWei, selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      setEthPerIndexToken(
        utils
          .parseEther("1")
          .mul(BigNumber.from(10).pow(18))
          .div(selectedItem.amountOutPerMilliEth)
          .mul(1000)
      );
    }
  }, [selectedItem, balance]);

  const handleEthChanged = (eth) => {
    const ethAmountInWei = utils.parseEther(eth.toString());
    setEthAmountInWei(ethAmountInWei.toString());
    const newTokenAmountInWei = selectedItem.amountOutPerMilliEth
      .mul(1000)
      .mul(ethAmountInWei)
      .div(BigNumber.from(10).pow(18))
      .toString();
    const newTokenAmount = utils.formatUnits(newTokenAmountInWei, "ether");
    setTokenAmountInWei(newTokenAmountInWei);
    setTokenAmount(newTokenAmount);
  };

  const handleIndexTokensChanged = (indexTokens) => {
    const indexAmountInWei = utils.parseUnits(
      indexTokens.toString(),
      selectedItem?.decimals ?? 18
    );
    const newTokenAmountInWei = indexAmountInWei.toString();
    const newTokenAmount = utils.formatUnits(newTokenAmountInWei, "ether");
    setTokenAmountInWei(newTokenAmountInWei);
    setTokenAmount(newTokenAmount);
    const newEthAmountInWei = indexAmountInWei
      .mul(BigNumber.from(10).pow(18))
      .div(selectedItem.amountOutPerMilliEth)
      .toString();
    const newAmount = utils.formatUnits(newEthAmountInWei, "ether");
    setEthAmountInWei(newEthAmountInWei);
    setEthAmount(newAmount);
  };

  const amountOne =
    selectedItem?.amountOutPerMilliEth?.mul(1000)?.toString() ?? "0";
  const amountTwo = ethPerIndexToken?.toString() ?? "0";

  return (
    <div className={clsx("v-buyIndex", `${cabinet}-buyIndex`)}>
      <div className={clsx("v-buyIndex-bottom", `${cabinet}-buyIndex-bottom`)}>
        <div
          className={clsx(
            "v-buyIndex-bottom-asset",
            `${cabinet}-buyIndex-bottom-asset`
          )}
        >
          <Eth />
          <span>ETH</span>
        </div>
        <div
          className={clsx(
            "v-buyIndex-bottom-values",
            `${cabinet}-buyIndex-bottom-values`
          )}
        >
          <div
            className={clsx(
              "v-buyIndex-bottom-values-eth-qty",
              `${cabinet}-buyIndex-bottom-values-eth-qty`
            )}
          >
            <NumberInput
              type="text"
              value={ethAmount}
              onChange={(e) => {
                const amountAsString = e.target.value;
                setEthAmount(amountAsString);
                if (isNaN(+amountAsString)) return;
                handleEthChanged(+amountAsString);
              }}
            />
          </div>
          <div
            className={clsx(
              "v-buyIndex-bottom-values-dollar-value",
              `${cabinet}-buyIndex-bottom-values-dollar-value`
            )}
          >
            {ethPrice * Number(utils.formatEther(ethAmountInWei))}
            <span>＄</span>
          </div>
        </div>
      </div>
      <div className={clsx("v-buyIndex-top", `${cabinet}-buyIndex-top`)}>
        <div
          className={clsx(
            "v-buyIndex-top-dropdown",
            `${cabinet}-buyIndex-top-dropdown`
          )}
        >
          <button
            className={clsx(
              "v-buyIndex-target",
              `${cabinet}-buyIndex-target`,
              indexes.filter((index) => index.symbol !== selectedItem.symbol)
                .length > 0 &&
                isOpen &&
                "open",
              indexes.filter((index) => index.symbol !== selectedItem.symbol)
                .length === 0 && "no-button"
            )}
            type="button"
            {...getToggleButtonProps()}
          >
            {selectedItem?.icon ?? <Pulse />}
            <span>{selectedItem?.symbol || "Elements"}</span>
            {indexes.filter((index) => index.symbol !== selectedItem.symbol)
              .length > 0 && (
              <span className="v-buyIndex-carret">
                {isOpen ? <UpCaret /> : <DownCaret />}
              </span>
            )}
          </button>
          {ReactDOM.createPortal(
            <div
              ref={setPopperElement}
              {...attributes}
              style={isOpen ? {} : { borderBottom: "none" }}
              className={clsx(
                "v-buyIndex-popper",
                `${cabinet}-buyIndex-popper`
              )}
            >
              <ul {...getMenuProps()}>
                {isOpen &&
                  indexes.filter(
                    (index) => index.symbol !== selectedItem.symbol
                  ).length > 0 &&
                  indexes.map((item, index) => {
                    if (item.symbol === selectedItem.symbol) return null;
                    return (
                      <li
                        style={
                          highlightedIndex === index ? { opacity: 0.9 } : {}
                        }
                        key={`${item.symbol}`}
                        {...getItemProps({ item, index })}
                      >
                        {item.icon ?? <Pulse />}
                        <span>{item.originalSymbol}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>,
            document.querySelector(".v-buyIndex-top-dropdown") || document.body
          )}
        </div>
        <div
          className={clsx(
            "v-buyIndex-top-input",
            `${cabinet}-buyIndex-top-input`
          )}
        >
          <div
            className={clsx(
              "v-buyIndex-top-input-left",
              `${cabinet}-buyIndex-top-input-left`
            )}
          >
            <NumberInput
              type="text"
              value={utils
                .formatUnits(
                  newAmountOut?.amountOut.toString() ?? "0",
                  selectedItem.decimals ?? 18
                )
                .toString()}
              disabled
              onChange={(e) => {
                setTokenAmount(e.target.value);
                if (isNaN(+e.target.value)) return;
                handleIndexTokensChanged(+e.target.value);
              }}
            />
          </div>
          <div
            className={clsx(
              "v-buyIndex-top-input-right",
              `${cabinet}-buyIndex-top-input-right`
            )}
          >
            <button
              className={clsx(
                "v-buyIndex-top-input-right-max",
                `${cabinet}-buyIndex-top-input-right-max`
              )}
              onClick={() => {
                const balanceAsString = utils.formatUnits(balance, "ether");
                handleEthChanged(balanceAsString);
                setEthAmount(balanceAsString);
              }}
            >
              [MAX]
            </button>
          </div>
        </div>
      </div>
      {selectedItem.amountOutPerMilliEth && ethPerIndexToken && (
        <div
          className={clsx("v-buyIndex-footer", `${cabinet}-buyIndex-footer`)}
        >
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setInvertExchange(!invertExchange)}
          >
            1 {invertExchange ? "ETH" : selectedItem.symbol} ={" "}
            <>
              {Number(
                utils
                  .formatUnits(
                    invertExchange ? amountOne : amountTwo,
                    invertExchange ? selectedItem.decimals : 18
                  )
                  .toString()
              ).toFixed(3)}{" "}
              {invertExchange ? selectedItem.symbol : "ETH"}
            </>
          </span>
          <span
            style={{
              color:
                slippageValues &&
                (slippageValues.priceImpact === PriceImpact.HIGH
                  ? "red"
                  : slippageValues.priceImpact === PriceImpact.MEDIUM
                  ? "orange"
                  : "green"),
              marginLeft: "5px",
            }}
          >
            ({slippageValues && slippageValues.slippage}% slippage)
          </span>
        </div>
      )}
    </div>
  );
};
