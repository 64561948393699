export enum NetworkSpeed {
  FAST = "fast",
  SLOW = "slow",
}
export const useNetworkSpeed = (): NetworkSpeed => {
  const connection =
    navigator?.connection || // @ts-ignore
    navigator?.mozConnection || // @ts-ignore
    navigator?.webkitConnection;
  if (navigator.connection) {
    return connection.effectiveType === "4g"
      ? NetworkSpeed.FAST
      : NetworkSpeed.SLOW;
  }
};
