import { ReactComponent as WhaleMarinerUnmuteIcon } from "../../GamePages/WhaleMariner/assets/icons/unmute.svg";
import { ReactComponent as FomoUnmuteIcon } from "../../GamePages/Fomo/assets/icons/unmute.svg";
import { ReactComponent as BullRunUnmuteIcon } from "../../GamePages/BullRun/assets/icons/unmute.svg";
import { ReactComponent as CookingCryptoUnmuteIcon } from "../../GamePages/CookingCrypto/assets/icons/unmute.svg";
import { ReactComponent as DefiInvadersUnmuteIcon } from "../../GamePages/DefiInvaders/assets/icons/unmute.svg";
import { ReactComponent as HypedApesUnmuteIcon } from "../../GamePages/HypedApes/assets/icons/unmute.svg";

export const UnmuteIcon = ({ cabinet }) => {
  switch (cabinet) {
    case "cookingCrypto":
      return <CookingCryptoUnmuteIcon />;
    case "hypedApes":
      return <HypedApesUnmuteIcon />;
    case "fomo":
      return <FomoUnmuteIcon />;
    case "bullRun":
      return <BullRunUnmuteIcon />;
    case "whaleMariner":
      return <WhaleMarinerUnmuteIcon />;
    case "defiInvaders":
      return <DefiInvadersUnmuteIcon />;
    default:
      return <span></span>;
  }
};
