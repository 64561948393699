import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { Contract } from "@ethersproject/contracts";
import { erc20Abi } from "../config/abis/erc20";
import { liquidityMigration } from "../config/victimMeta";

export const getTokenData = async (
  address: string,
  provider: Provider | Signer,
  account: string
) => {
  const calls = (owner) => [
    { name: "balanceOf", args: [owner] },
    { name: "symbol", args: [] },
    { name: "decimals", args: [] },
    { name: "name", args: [] },
    { name: "allowance", args: [owner, liquidityMigration] },
  ];

  const contract = new Contract(address, erc20Abi, provider);
  const [balance, symbol, decimals, name, allowance] = await Promise.all(
    calls(account).map(({ args, name }) => {
      return contract[name](...args);
    })
  );

  return {
    address,
    balance,
    symbol,
    decimals,
    name,
    allowance,
  };
};
