import React, { useEffect } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { useEthers } from "@usedapp/core";
export const GuardedRoute = (props) => {
  const { account } = useEthers();
  const { url } = useRouteMatch();
  const history = useHistory();

  // useEffect(() => {
  //   if (!account) {
  //     const parentPath = url.substring(0, url.lastIndexOf("/"));
  //     history.replace([parentPath, "/connect-wallet"].join(""));
  //   }
  // }, [account, history, url]);

  return <Route {...props} />;
};
