import axios from "axios";

export const getStakedCount = async () => {
  const url =
    "https://api.thegraph.com/subgraphs/name/ensofinance/enso-liquidity-migration";
  const payload = {
    query: `
      query StakedCount {
        adapters {
          name
          staked
        }
      }
    `,
  };

  try {
    const response = await axios.post(url, payload);
    return response.data.data.adapters;
  } catch (e) {
    return [];
  }
};
