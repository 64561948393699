import axios from "axios";

export const getTotalTvl = async () => {
  const url =
    "https://api.thegraph.com/subgraphs/name/ensofinance/enso-liquidity-migration";
  const payload = {
    query: `
      query Tvl {
        tokens {
          id
          decimals
          stakedAmount
        }
      }
    `,
  };

  try {
    const response = await axios.post(url, payload);
    return response.data.data.tokens;
  } catch (e) {
    return [];
  }
};
