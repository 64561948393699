import { Button } from "../Button";
import React, { ReactNode, useEffect, useState } from "react";
import "./infoDropdown.scss";
import clsx from "clsx";
interface InfoDropdownProps {
  cabinet: string;
  links: any[];
  moreIcon: ReactNode;
  style?: {}
}
export const InfoDropdown = ({
  cabinet,
  links,
  moreIcon,
  style
}: InfoDropdownProps) => {
  const iconSize = ["defiInvaders", "fomo"].includes(cabinet)
    ? "squareSmall"
    : "square";
  
  const [moreInfo, setMoreInfo] = useState(false);

  const handleClose = e => {
    const moreInfoContainer = document.getElementById("moreInfo")
    if (moreInfoContainer && !moreInfoContainer.contains(e.target)) setMoreInfo(false)
  }

  useEffect(() => {
    window.addEventListener("click", handleClose)
    
    return () => {
      window.removeEventListener("click", handleClose)
    }
  }, [])

  return (
    <>
      <Button style={{ height: style["buttonHeight"] }} id="moreInfo" onClick={() => setMoreInfo(!moreInfo)} variant={iconSize} cabinet={cabinet} icon={moreIcon} />

      {moreInfo &&
        <div
          className={`v-infoDropdown ${cabinet}-infoDropdown`}
          style={style}
        >
          <ul
          className={clsx("v-infoDropdown-ul", `${cabinet}-infoDropdown-ul`)}
          >
            {links.map((link) => (
              <a href={link.url} target="_blank" rel="noreferrer" key={`${cabinet}-infoDropdown-key-${link.title}`}>
                <li
                  className={clsx(
                    "v-infoDropdown-li",
                    `${cabinet}-infoDropdown-li`
                  )}
                >
                  {link.title}
                </li>
              </a>
            ))}
          </ul>
        </div>
      }
      </>
  );
};
